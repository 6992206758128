import { LanguageResource } from "./index";

/**
 * Defines the english to english translations
 */
export const en_hu: LanguageResource = {
  /**
   * General
   */
  VAT: "ÁFA",
  VATPrint: "TVA",
  PriceBase: "Alap Ár",
  AddA: "Hozzáadás",
  Home: "Itthon",
  LoadData: "Adatok betöltése",
  HideData: "Adatok elrejtése",
  ShowData: "Adatok megjelenítése",
  AssociatedWith: "A következővel társítva",
  AddNewWorker: "Új dolgozó hozzáadása",
  BirthDate: "Születési dátum",
  Account: "Fiók",
  AccountSettings: "Fiók beállításai",
  AccountStatus: "Fiók státusza",
  AccountSummary: "Fiókösszefoglaló",
  AccountType: "Fiók típusa",
  ActionCannotBeUndone: "Ez a művelet <strong>nem vonható vissza</strong>.",
  Activate: "Aktiválás",
  ActivePackage: "Aktív csomag",
  AddNewCarType: "Új autótípus hozzáadása",
  AddNewClient: "Új ügyfél hozzáadása",
  AddNewCondition: "Új feltétel hozzáadása",
  AddNewContact: "Új kapcsolat hozzáadása",
  AddNewLoyaltyCard: "Új hűségkártya hozzáadása",
  AddNewOrganization: "Új munkapont hozzáadása",
  AddNewProduct: "Új termék hozzáadása",
  AddNewTyreHotel: "Új gumiabroncs hotel hozzáadása",
  AddNewPaymentMethod: "Új fizetési mód hozzáadása",
  AddNewAppointmentGroup: "Új programálás csoport hozzáadása",
  AddNewUser: "Új felhasználó hozzáadása",
  AddNewGuaranty: "Új garancia hozzáadása",
  AddNewTimesheet: "Új munkalap hozzáadása",
  AddNewAppointment: "Új programálás hozzáadása",
  AddProductOrService: "Termék / szolgáltatás hozzáadása",
  AddTyreHotel: "Gumiabroncs hotel hozzáadása",
  Advanced: "Részletes",
  AdvancedReport: "Részletes jelentés",
  AdvancedReports: "Részletes jelentés",
  AppointmentsReport: "Programálás jelentés",
  SuspendedWoReport: "Felfüggesztett munkamegrendelések jelentése",
  All: "Minden",
  Appearance: "Megjelenés",
  Appointment: "Programálás",
  AppointmentGroups: "Programálási csoportok",
  Appointments: "Programálások",
  AppointmentsIntroText: "Kövesse nyomon és kezelje a programálásokat",
  AreYouAbsolutelySure: "Teljesen biztos vagy benne?",
  AreYouARobot: "Robot vagy?",
  AreYouSure: "Biztos vagy benne?",
  AvailableModulesForAccount: "Elérhető modulok a fiókjához:",
  BackToDashboard: "Vissza a kezelőfelületre",
  BeforeYouSuspendTheAccount:
    "Mielőtt felfüggeszti a kapcsolódó fiókot <strong> {{account}} </strong>, kérjük, vegye figyelembe:",
  BiggerThan: "Nagyobb, mint",
  BiggerThanOrEqual: "Nagyobb mint vagy egyenlő",
  BoltTorque: "Csavarnyomaték (Nm)",
  BoltTorqueShort: "Nyomaték",
  BoltTorquePrint: "Cuplu de strangere (Nm)",
  CalculatingDynamicPricing: "Dinamikus árképzés kiszámítása.",
  Cancel: "Törlés",
  CardActive: "Kártya aktív",
  CarData: "Autó adatai",
  CardNumber: "Kártya száma",
  CarType: "Autó típusa",
  CarTypePrint: "Tipul de mașină",
  CarTypes: "Autó típusok",
  CarTypesIntroText: "Kezelje és kövesse nyomon az autótípusokat.",
  AppointmentGroupsIntroText: "Időpontcsoportok hozzáadása és kezelése.",
  ChangeLanguage: "A használt nyelv megváltoztatása.",
  ChangePassword: "Jelszó módosítása",
  CheckYourNotifications: "Ellenőrizze értesítéseit a részletekért.",
  Client: "Ügyfél",
  ClientCode: "Ügyfélkód",
  ClientData: "Ügyféladatok",
  ClientDataPrint: "Date client",
  ClientName: "Ügyfél neve",
  Clients: "Ügyfelek",
  ClientsIntroText: "Ügyfelei kezelése és nyomon követése.",
  ClientType: "Ügyfél típusa",
  ClientTypePrint: "Tipul de client",
  CloseAccount: "Fiók bezárása",
  CloseAccountInfo:
    "A fiók bezárása véglegesen eltávolítja az adatokat és a tartalmat..",
  Closed: "Zárva",
  Company: "Cég",
  CompanyData: "Cégadatok",
  CompanyMonthlyReportsTotalization: "Céges havi jelentések összesítése",
  CompanyMonthlyReportTotalized: "Havi jelentések összesítése",
  CompanyReport: "Céges jelentés",
  Condition: "Feltétel",
  ConfirmNewPassword: "Új jelszó megerősítése",
  ContactAdminToExtendPackage:
    "Kérjük, lépjen kapcsolatba a munkapont adminisztrátorával a jelenlegi csomag bővítése érdekében.",
  ContactData: "Kapcsolattartási adatok",
  Contracts: "Szerződések",
  Copyright: "© {{year}} VehiGo. Minden jog fenntartva",
  CopyrightShort: "© {{year}}",
  Create: "Létrehoz",
  CreateITP: "Létrehoz ITP",
  CreatedAt: "Létrehozás dátuma",
  UpdatedAt: "Frissítés dátuma",
  CreatedTime: "Létrehozás ideje",
  CreateTyreHotel: "Gumiabroncshotel létrehozása",
  CreateWorkOrders: "Munkamegrendelések létrehozása",
  CreatingTyreHotel: "Gumiabroncshotel létrehozása",
  CurrentMonth: "Aktuális hónap",
  CurrentPassword: "Aktuális jelszó",
  CurrentStatus: "Aktuális státusz",
  CurrentWeek: "Aktuális hét",
  CustomizeAppearance:
    "Az alkalmazás megjelenésének testreszabása a készüléken.",
  Daily: "Napi",
  DailyReport: "Napi jelentés",
  Dashboard: "Kezelőfelület",
  DashboardPageIntro:
    "Hozzon létre, kezelje és kövesse nyomon ügyfelei megrendeléseit.",
  Date: "Dátum",
  DatePrint: "Dată",
  DateRange: "Dátum intervallum",
  Deactivate: "Inaktiválás",
  Delegate: "Delegálás",
  DelegateName: "Küldött neve",
  DelegateNamePrint: "Numele delegatului",
  Delete: "Törlés",
  DeleteAllNotifications: "Minden értesítés törlése",
  DeleteClientWarning: "Ez <strong>véglegesen</strong> törli az ügyfelet.",
  DeleteInProgress: "Törlés folyamatban",
  DeleteLoyaltyCardWarning:
    "Ez <strong>véglegesen</strong> törli a hűségkártyát.",
  DeleteNotificationsWarning: "Az összes értesítést törölni fogja.",
  DeleteTimesheetWarning:
    "Ez <strong> véglegesen </strong> törli a időbeosztást.",
  DeleteOrganizationWarning:
    "Ez <strong>véglegesen</strong> törli a munkapontot.",
  DeleteProductWarning: "Ez <strong>véglegesen</strong> törli a terméket.",
  DeleteServiceWarning:
    "Ez <strong>véglegesen</strong> törli ezt a szolgáltatást.",
  DeleteThisProductWarning:
    "Ez <strong>véglegesen</strong> törli ezt a terméket.",
  DeleteTyreHotelWarning:
    "Ez <strong>véglegesen</strong> törli a gumiabroncshotelt.",
  DeleteUserWarning: "Ez <strong>véglegesen</strong> törli a felhasználót.",
  DeleteAccountWarning: "Ez <strong>véglegesen</strong> törli a fiókot.",
  DeleteWorkerWarning: "Ez <strong>véglegesen</strong> törli a dolgozót.",
  DeleteWorkOrderWarning:
    "Ez <strong>véglegesen</strong> törli a munkamegrendelést.",
  DeleteGuarantyWarning: "Ez <strong> véglegesen </strong> törli a garanciát.",
  DeletingTyreHotel: "Gumiabroncshotel törlése",
  DeletingWorkOrder: "Munkamegrendelés törlése",
  DeletingTimesheet: "Időbeosztás törlése",
  DepositTyreHotel: "Letét / Gumiabroncshotel",
  Description: "Leírás",
  DescriptionPrint: "Descriere",
  DetailedReport: "Részletes jelentés",
  Discount: "Kedvezmény",
  DiscountPrint: "Reducere",
  DiscountType: "Kedvezmény típusa",
  DiscountTypePercentage: "Százalék",
  DiscountTypeValue: "Érték (HUF)",
  DiscountValue: "Kedvezmény értéke",
  DistanceDone: "Megtett távolság (km)",
  DocumentPrefix: "Dokumentum előtag",
  DocumentSuffix: "Dokumentum utótag",
  Done: "Megtörtént",
  DownloadAsPDF: "Letöltés PDF-ként",
  DropHereToDelete: "A törléshez kattintson ide",
  ITPForm: "ITP form",
  Edit: "Szerkesztés",
  EditITP: "Szerkesztés ITP",
  EditStatus: "Szerkesztés státusza",
  EditClient: "Ügyfél szerkesztése",
  EditLoyaltyCard: "Hűségkártya szerkesztése",
  EditProduct: "Termék szerkesztése",
  EditGuarantee: "Garancia szerkesztése",
  EditService: "Szolgáltatás szerkesztése",
  EditTyreHotel: "Gumiabroncs hotel szerkesztése",
  ViewTyreHotel: "Gumiabroncs hotel megtekintése",
  EditWorkOrder: "Munkamegrendelés szerkesztése",
  EditWorkOrderITP: "ITP szerkesztése",
  EditWorkOrderIntro: "Megrendelés frissítése gyorsan és egyszerűen.",
  EditDraftWorkOrder: "Munkamegrendelés-piszkozat szerkesztése",
  EditDraftWorkOrderIntro:
    "Munka megrendelés piszkozatának frissítése gyorsan és egyszerűen.",
  EditCustomerOrder: "Megrendelés szerkesztése",
  CreateWorkOrder: "Munkamegrendelés létrehozása",
  ElapsedTime: "Lejárt idő",
  Email: "E-mail",
  en_en: "HU",
  en_enLabel: "Angol",
  en_hu: "HU",
  en_huLabel: "Magyar",
  en_ro: "RO",
  en_roLabel: "Román",
  EntityType: "A szervezet típusa",
  Equals: "Egyenlő",
  Expendable: "Leírható",
  ExpiresIn: "Lejárat",
  Export: "Exportálás",
  Field: "Mező",
  FirstName: "Keresztnév",
  LastName: "Vezetéknév",
  FlatPriceExplanation: "Az új ár megegyezik a standard árral.",
  ForClient: "Az ügyfél számára",
  ForWarehouse: "A raktár számára",
  General: "Általános",
  GeneralData: "Általános adatok",
  GenerateCardNumber: "Kártyaszám generálása",
  GettingProductAndServices: "Termékek és szolgáltatások beszerzése.",
  GettingWorkOrderData: "Munkamegrendelés adatainak lekérdezése",
  GroupByDay: "Csoportosítás napok szerint",
  HigherPriceExplanation: "Az új ár magasabb, mint a standard ár.",
  HotelOrDeposit: "Hotel / Letét",
  InProgress: "Folyamatban",
  InvalidEmail: "Érvénytelen e-mail",
  InvalidNumber: "Érvénytelen szám",
  InvalidPlateNumber: "Érvénytelen rendszám",
  IUnderstandDeleteCard: "Értem, törölje ezt a kártyát.",
  IUnderstandDeleteClient: "Értem, törölje ezt az ügyfelet.",
  IUnderstandDeleteHotel: "Értem, törölje ezt az abroncshotelt.",
  IUnderstandDeleteOrganization: "Értem, törölje ezt a mukapontot.",
  IUnderstandDeleteProduct: "Értem, törölje ezt a terméket.",
  IUnderstandDeleteUser: "Értem, töröld ezt a felhasználót.",
  IUnderstandDeleteAccount: "Értem, törölje ezt a fiókot.",
  IUnderstandDeleteWorker: "Értem, törölje ezt a dolgozót.",
  IUnderstandDeleteWorkOrder: "Értem, törölje ezt a munkamegrendelést.",
  IUnderstandDeleteGuaranty: "Értem, törölje ezt a garanciát.",
  IUnderstandDeleteTimesheet: "Megértettem, törölje ezt ay időbeosztást.",
  Language: "Nyelv",
  LegalEntity: "Jogi személy",
  LessThan: "Kevesebb, mint",
  LessThanOrEqual: "Kevesebb vagy egyenlő mint",
  LoadingOptions: "Betöltési lehetőségek",
  Location: "Helyszín",
  Logout: "Kijelentkezés",
  LowerPriceExplanation: "Az új ár alacsonyabb, mint a standard ár.",
  LoyalClient: "Hűséges ügyfél",
  LoyalClients: "Hűséges ügyfelek",
  LoyaltyCard: "Hűségkártya",
  LoyaltyCardPrint: "Card de fidelitate",
  LoyaltyCards: "Hűségkártyák",
  LoyaltyCardsIntroText: "Hűségkártyáinak kezelése és nyomon követése.",
  Management: "Menedzsment",
  Manufacturer: "Gyártó",
  MarkAllAsRead: "Jelöljön meg mindent olvasottként",
  MarkAsRead: "Jelölje olvasottként",
  MatchingCardFound: "Megfelelő kártyát találtam",
  MessageBoxError: "Hiba",
  MessageBoxInfo: "Információ",
  MessageBoxSuccess: "Siker",
  MessageBoxWarning: "Figyelmeztetés",
  MessageShownInError:
    "Ha úgy gondolja, hogy ez az üzenet hibásan jelenik meg, akkor lépjen kapcsolatba velünk az alábbi gomb segítségével",
  Min1Worker: "Min. 1 dolgozó",
  Model: "Modell",
  ModelPrint: "Model",
  Monthly: "Havi",
  MonthlyReport: "Havi jelentés",
  MonthlyTotalOrders: "Havi összes rendelés",
  New: "Új",
  Skipped: "Kihagyott",
  NewPassword: "Új jelszó",
  NewPrice: "Új ár",
  NewTyre: "Új gumiabroncs",
  NewTyres: "Új gumiabroncsok",
  NewTyresPrint: "Anvelope noi",
  NewVersionAvailable: "Van egy újabb verzió is. Kérjük, frissítse",
  NewWorkOrder: "Új munkamegrendelés",
  ITP: "ITP",
  ITPIntroText: "Gyors és egyszerű ITP létrehozása.",
  NewWorkOrderIntro: "Gyors és egyszerű ügyfélmegrendelés létrehozása.",
  NextStep: "Következő lépés",
  No: "Nem",
  NoBarcode:
    "Sajnáljuk, nem találtunk vonalkódot. Kérjük, próbálja meg újra, vagy adja meg a vonalkódot kézzel az alábbiakban.",
  NoNewNotifications: "Nincsenek új értesítései.",
  NoOptions: "Nincsenek lehetőségek",
  Options: "Lehetőségek",
  NoScanResultsPleaseTryAgain: "Nem találtunk kártyát. Kérjük, próbálja újra.",
  NoSearchResultsFound: "Nincs találat.",
  NotFound: "Nem találták",
  Notifications: "Értesítések",
  NotificationsIntroText: "Értesítések kezelése és nyomon követése",
  NoUnreadNotifications: "Nincsenek olvasatlan értesítései.",
  Observations: "Megfigyelések",
  Observation: "Observation",
  ObservationsPrint: "Observații",
  OnHold: "Ütemezett",
  OpenNotifications: "Értesítések megnyitása",
  OrderNumber: "Rendelési szám",
  OrderNumberPrint: "Număr de comandă",
  Orders: "Rendelések",
  Organizations: "Munkapontok",
  OrganizationsIntroText: "A munkapontok kezelése és nyomon követése.",
  PastMonth: "Múlt hónap",
  PastWeek: "Múlt hét",
  Payment: "Fizetés",
  PaymentMethods: "Fizetési módok",
  PaymentType: "Fizetési típus",
  PaymentTypes: "Fizetési típusok",
  Person: "Személy",
  Phone: "Telefon",
  PhoneFix: "Telefonszám",
  PhoneMobile: "Mobiltelefon",
  PhoneNumber: "Telefonszám",
  PhoneNumberPrint: "Număr de telefon",
  PickDateRange: "Válasszon egy dátumtartományt",
  PlateNumber: "Rendszám",
  PleaseCompleteTheRecaptcha: "Kérjük, töltse ki a recaptcha-t",
  PleaseContactAccountAdmin:
    "Kérjük, lépjen kapcsolatba fiókadminisztrátorával fiókja helyreállításához.",
  PleaseProvideAtLeastOneFilter: "Kérjük, adjon meg legalább egy szűrőt",
  PleaseUseBiggerScreen:
    "Kérjük, legalább <strong> 1100 pixel </strong> széles képernyőt használjon, elnézést kérünk a kellemetlenségekért.",
  PostCode: "Postai irányítószám",
  PreviousStep: "Előző lépés",
  PriceActivated: "Aktivált ár",
  PriceConditions: "Árfeltételek",
  Print: "Nyomtatás",
  ProcessingYourRequest: "A kérés feldolgozása",
  ProductIsInactive:
    "A termék ára deaktiválva van. Ezt a Munkaállomás beállításai / Termékek menüpontban lehet megváltoztatni.",
  ProductName: "Termék neve",
  Products: "Termékek",
  ProductsPrint: "Produse",
  ProductsAndServices: "Termékek / Szolgáltatások",
  ProductsIntroText: "Kezelje és kövesse nyomon termékeit.",
  ServicesIntroText: "Kezelje és kövesse nyomon szolgáltatásait.",
  ProductType: "Termék típusa",
  Quantity: "Mennyiség",
  QuantityPrint: "Cantitate",
  QueryInterval: "Lekérdezési intervallum",
  RecoverAccount: "Fiók helyreállítása",
  RecoverAccountInfo:
    "Az Ön fiókja jelenleg felfüggesztve van, ezért egyes funkciók nem elérhetőek.",
  RecoverAccountRestoreFeatures:
    "Bármikor visszaállíthatja fiókját, hogy a funkciókat visszaállítsa.",
  RecoveringYourAccount: "Fiókja visszaállítása",
  RegNumber: "Cégjegyzékszám",
  ReleaseToDelete: "Engedje el a törléshez",
  RemoveThisNotification: "Távolítsa el ezt az értesítést",
  Reports: "Jelentések",
  ReportsPageIntroText: "Jelentések készitése és kezelése",
  ReportTitle: "Jelentés címe",
  RequestTimeout: "A kérése lejárt",
  RequiredField: "Kötelező mező",
  ResetFormWarning: "Az űrlap visszaállítása előtt áll.",
  Save: "Mentés",
  SaveDraft: "Piszkozat mentése",
  SavedSuccessfully: "Sikeresen mentve",
  Saving: "Mentés",
  ScanCard: "Kártya beolvasása",
  Scheduled: "Ütemezett",
  SelectAProduct: "Termék kiválasztása",
  SelectAService: "Szolgáltatás kiválasztása",
  SelectExpendable: "Válassza ki a leírhatót",
  Settings: "Beállítások",
  Shipments: "Küldemények",
  ShortName: "Rövid név",
  ShowResults: "Eredmények megjelenítése",
  SmartTableResultsCount: "eredmény",
  SomeFeaturesNoLongerAvailable: "Néhány funkció már nem lesz elérhető",
  SortOrder: "Megrendelés",
  SortOrderField: "Mező",
  Sta: "Standard",
  Status: "Státusz",
  StillFetchingProductsPleaseTryAgain:
    "Sajnálom! Még mindig folyamatban van a termékek és szolgáltatások lekérdezése. Kérjük, próbálja újra",
  Subtotal: "Részösszeg",
  SubtotalPrint: "Subtotal",
  Summary: "Összefoglaló",
  Support: "Támogatás",
  SuspendAccount: "Függessze fel a fiókot",
  SuspendAccountInfoReviewed:
    "Átnéztem a fenti információkat. Kész vagyok felfüggeszteni a fiókomat.",
  SuspendAccountNotPermanent: "A fiók felfüggesztése nem végleges.",
  SuspendAccountReactivate:
    "Bármikor újra aktiválhatja, és az adatai a fiókban maradnak, amíg inaktív..",
  SuspendAvailableFeatures:
    "Csak a fiók beállításai lesznek elérhetők az admin fiókok számára, a felfüggesztett fiók újraaktiválása céljából.",
  SuspendImpactsUsers:
    "<strong>{{userCount}} felhasználók lesznek érintettek.</strong>",
  SuspendingYourAccount: "Fiókjának felfüggesztése",
  StandardPrice: "Standard ár: <strong>{{price}}</strong>",
  Switch: "Váltás",
  SwitchAccount: "Fiókváltás",
  Syncronizing: "Szinkronizálás",
  TaskCompletion: "Feladat befejezése",
  TaxAndRegistration: "Adószám / Cégjegyzékszám",
  TaxNumber: "Adószám",
  Timesheet: "Munkaidő-nyilvántartás",
  TimesheetExample:
    "Pl.: H-P 08:00 - 18:00 | Szombat 08:00 - 13:00 | Vasárnap zárva",
  Timesheets: "Időbeosztások",
  TimesheetsIntroText: "Kezelje és kövesse nyomon alkalmazottai időbeosztását.",
  Today: "Ma",
  Total: "Összesen",
  TotalVAT: "Összesen (ÁFA-val)",
  TotalPrint: "Total",
  TotalPrintVAT: "Total (cu TVA)",
  TotalOrders: "Összes rendelés",
  TotalQuantity: "Teljes mennyiség",
  TotalReport: "Összes jelentés",
  Type: "Típus",
  TyreData: "Gumiabroncs adatok",
  TyreDimensions: "Gumiabroncs méretei",
  TyreDimensionsPrint: "Dimensiunile anvelopei",
  TyreDimensionsShort: "Gumiabroncs méret.",
  TyreHeight: "Gumiabroncs magassága",
  TyreHeightShort: "Magasság",
  TyreHotel: "Gumiabroncs hotel",
  TyreHotels: "Gumiabroncs hotelek",
  TyreHotelsIntroText: "Gumiabroncshotelek kezelése és nyomon követése.",
  TyreName: "Gumiabroncs neve",
  TyrePressure: "Gumiabroncsnyomás (Bar)",
  TyrePressureShort: "Nyomás",
  TyrePressurePrint: "Presiunea pneurilor (Bar)",
  TyreQuantity: "Gumiabroncs mennyisége",
  TyreRim: "Gumiabroncs felni",
  TyreRimShort: "Felni",
  TyreService: "Gumiabroncs szerviz",
  TyreServicePackageDescription:
    "Ez a csomag tartalmazza a gumiabroncs szervizeléssel kapcsolatos összes funkciót. Az ár munkapontonként és havonta értendő, az alapértelmezett időtartam pedig 30 nap",
  TyreWaste: "Gumiabroncs-hulladék",
  TyreWasteShort: "Hulladék",
  TyreWidth: "Gumiabroncs szélessége",
  TyreWidthShort: "Széllesség",
  UmPlaceholder: "PC",
  UnitOfMeasure: "Mértékegység",
  UnknownTranslation: "[Ismeretlen fordítás]",
  Unlock: "Oldja fel",
  Unread: "Olvasatlan",
  UnsupportedScreenResolution: "Az Ön képernyőfelbontása nem támogatott",
  Update: "Frissítés",
  UpdateInProgress: "Frissítés folyamatban",
  UpdatePasswordText:
    "Érdemes olyan erős jelszót használni, amelyet máshol nem használ.",
  UpdateWorkOrder: "Munkamegrendelés frissítése",
  UpdateYourPassword: "Frissítse jelszavát",
  UpdatingTyreHotel: "Gumiabroncshotel frissítése",
  UpdatingWorkOrder: "Munkamegrendelés frissítése",
  UploadExcel: "Excel feltöltése",
  Users: "Felhasználók",
  UsersIntroText:
    "Munkapontjai felhasználói fiókjainak kezelése és nyomon követése.",
  Value: "Érték",
  VehicleData: "Járműadatok",
  VehicleType: "Jármű típusa",
  ViewAllFilters: "Minden szűrő megtekintése",
  ViewProfile: "Profil megtekintése",
  ViewWorkOrder: "Munkarend megtekintése",
  ViewWorkOrderITP: "ITP megtekintése",
  ViewWorkOrderIntro: "Fedezze fel a munkamegrendelés adatait.",
  VinNumber: "VIN szám",
  VsLastMonth: "az elmúlt hónaphoz képest",
  VsLastWeek: "múlt héthez képest",
  VsLastYear: "tavalyi évhez képest",
  VsThisMonth: "ehhez a hónaphoz viszonyítva",
  VsThisWeek: "ehhez a héthez viszonyítva",
  VsThisYear: "ehhez az évhez viszonyítva",
  VsYesterday: "tegnaphoz viszonyítva",
  Waste: "Hulladék",
  WasteReport: "Hulladékjelentés",
  WeApologizeForTheInconvenience: "Elnézést kérünk a kellemetlenségekért.",
  WeeklyTotalOrders: "Heti összes rendelés",
  WelcomeBack: "Üdvözöljük újra",
  WheelType: "Keréktípus",
  WorkerAndOperatorAccountsRestrictedAccess:
    "Minden dolgozói és üzemeltetői fiók nem fog tudni hozzáférni a tartalomhoz, amíg az admin fiókot vissza nem állítják..",
  Workers: "Dolgozók",
  WorkersPrint: "Muncitori",
  WorkersReport: "Dolgozók jelentése",
  WorkOrder: "Munkamegrendelés",
  WorkOrderITP: "Munkamegrendelés - ITP",
  WorkOrderCreated: "Munkamegrendelés sikeresen létrehozva",
  WorkOrderNotFound: "Nem találtuk meg a keresett munkamegrendelést.",
  WorkOrderRemoved: "Ezt a munkamegrendelést törölték, és már nem elérhető.",
  DraftWorkOrderNotFound:
    "Nem találtuk meg a keresett munkamegrendelés piszkozatát.",
  DraftWorkOrderRemoved:
    "Ezt a munkamegrendelési piszkozatot törölték, és már nem elérhető..",
  WorkOrders: "Munkamegrendelések",
  WorkOrdersCountTotal: "Munkamegrendelések összesített mennyisége",
  WorkOrdersIntroText: "Ügyfelei megrendeléseinek kezelése és nyomon követése.",
  WorkersIntroText: "Kezelje és kövesse nyomon alkalmazottait.",
  WorkOrderType: "Munkamegrendelés típusa",
  WorkOrderTypePrint: "Tip comandă de lucru",
  WorkOrderUpdated: "Munkamegrendelés sikeresen frissítve",
  DraftUpdatedSuccessfully: "Sikeresen frissített piszkozat",
  WorkOrderUuidExample: "Például: DEM-1234567890-BR",
  WorkOverview: "Munka áttekintése",
  WorkOverviewIntro: "Ezek az elmúlt 1 nap munkamegrendelései",
  WorkstationSettings: "Munkaállomás",
  Year: "Év",
  YearlyTotalOrders: "Éves összes megrendelés",
  Yes: "Igen",
  YouCanBeginScanningTheCard: "Megkezdheti a kártya beolvasását",
  YourAccountIsSuspended: "A fiókja felfüggesztésre került",
  YourNextBillingDateIs: "A következő számlázási dátum",
  SetupYourPin: "Állítsa be a PIN-kódját",
  SetupPinText:
    "Hozzon létre egy 4 számjegyű PIN-kódot, hogy felgyorsítsa a bejelentkezést, és ne feledje, hogy ne ossza meg senkivel!",
  UpdateYourPin: "Frissítse PIN-kódját",
  UpdatePinText:
    "Használjon egy megjegyezhető pin-t, hogy felgyorsítsa a bejelentkezést, és ne feledje, hogy ne ossza meg senkivel!",
  TooShort: "Túl rövid",
  VeryWeak: "Nagyon gyenge",
  Weak: "Gyenge",
  Medium: "Közepes",
  Strong: "Erős",
  VeryStrong: "Nagyon erős",
  PasswordMustMeetFollowingRequirements:
    "A jelszónak a következő követelményeknek kell megfelelnie:",
  PleaseProvideAStrongPassword: "Kérjük, adjon meg egy erős jelszót.",
  AtLeastOneLowercaseLetter: "Legalább egy kisbetű.",
  AtLeastOneCapitalLetter: "Legalább egy nagybetű.",
  AtLeastOneNumber: "Legalább egy szám.",
  AtLeastOneSpecialCharacter: "Legalább egy speciális karakter.",
  AtLeast8CharactersLong: "Legalább 8 karakter hosszú.",
  PasswordsMustMatch: "A jelszavaknak meg kell egyezniük.",
  Security: "Biztonság",
  SetupPin: "PIN-kód beállítása",
  UpdatePin: "PIN-kód frissítése",
  PaymentMethodsIntroText: "Fizetési módok kezelése.",
  Working: "Aktív",
  GoneHome: "Otthon",
  Lunch: "Ebéd",
  Break: "Szünet",
  OnHoliday: "Szabadságon",
  Away: "Nem elerhető",
  Loading: "Betöltés",
  SelectStatus: "Válassza ki a státuszt",
  Guaranties: "Garanciák",
  GuarantiesIntroText: "Kezelje és kövesse nyomon garanciáit",
  CreatedByUser: "Felhasználó által létrehozott",
  CreatedDate: "Létrehozás dátuma",
  CreatedBy: "Létrehozva",
  Details: "Részletek",
  ChooseAClient: "Válasszon ügyfelet",
  DraftCreated: "Sikeresen létrehozott piszkozat",
  LiveTimesheet: "Élő időbeosztás",
  LiveTimesheetIntroText: "A felhasználók státuszának élő nyomon követése.",
  DayShorten: "d",
  StartDate: "Kezdeti dátum",
  EndDate: "Befejezés dátuma",
  CompanyInformation:
    "Companie <strong>{{name}}</strong> | C.I.F. / C.U.I <strong>{{vatNumber}}</strong> | Nr. de înregistrare <strong>{{regNumber}}</strong>",
  CompanyAddress: "Adresă <strong>{{fullAddress}}</strong>>",
  CompanyContactInfo:
    " | Telefon <strong>{{phone}}</strong> | Email <strong>{{email}}</strong>",
  CompanySignature: "Céges aláírás",
  CompanySignaturePrint: "Semnătura companiei",
  CompanyConsilierPrint: "Consilier service",
  ClientSignature: "Ügyfél aláírása",
  ClientSignaturePrint: "Semnătura clientului",
  SignaturePrint: "Semnătura",
  CertificateOfQualityAndGuarantee: "Minőségi és garanciális tanúsítvány",
  CertificateOfQualityAndGuaranteePrint: "Certificat de calitate și garanție",
  QualityAndGuaranteeParagraphOne:
    "Unitatea service garantează lucrarea executată*) și conformitatea acesteia**), potrivit OUG140/2021 *3 luni de la dată recepției autovehiculului, dacă lucrarea nu anecesitat înlocuiri de piese sau dacă s-a executat cu piesă clientului.",
  QualityAndGuaranteeParagraphTwo:
    "** Garanția pieselor furnizate de unitate, în baza OUG140/2021 - conf. declarației producătorului/prin convenție între părți, condițional, de resp. indicațiilor deexploatare prevăzute de producătorul autovehiculului este de ___ luni.",
  QualityAndGuaranteeParagraphThree:
    "*** La cererea BENEFICIARULUI unitatea va asigura vizualizarea activităților desfășurate și înregistrări care să dovedească efectuarea inspecțiilor finale.",
  QualityAndGuaranteeParagraphFour:
    "**** Nu se acordă garanție pentru piese furnizate de client.",
  WorkOrderClientDisclaimerOne:
    "Clientul a asistat și certificat prin semnătura de mai sus strângerea roților pe autovehivul și are obligația de a verifica prezoanele după 50 de km.",
  WorkOrderCustomDisclaimerParagraphOne:
    "Nu ne asumăm răspunderea  pentru deteriorări ale anvelopei sau jantei care nu au fost observate la efectuarea schimbului (gâlme,bolfe,cipituri, uzura neuniformă,separări de material, etc)",
  WorkOrderCustomDisclaimerParagraphTwo:
    "Unitatea service garantează conform OUG140/202, garanție 10 zile pentru manoperă și pentru produsele furnizate de unitatea noastră, conform cu certificatele de garanție emise de furnizor.",
  OrderDetails: "Megrendelés részletei",
  OrderDetailsPrint: "Detalii de comandă",
  CarPlate: "Autó rendszámtáblája",
  CarPlatePrint: "Plăcuța de înmatriculare a mașinii",
  CarManufacturer: "Autó gyártója",
  CarManufacturerPrint: "Marcă auto",
  CarKm: "Autó km",
  CarKmPrint: "Mașină km",
  RequestedServices: "Igényelt szolgáltatások",
  RequestedServicesPrint: "Servicii solicitate",
  ExecutedServices: "Kivitelezett szolgáltatások",
  ExecutedServicesPrint: "Servicii executate",
  MeasurementUnit: "UM",
  SelectAUser: "Felhasználó kiválasztása",
  TimesheetReports: "Időbeosztási jelentések",
  TimesheetReportsIntroText: "Időbeosztási jelentések bevezető szövege.",
  SelectWorkOrderType: "Munkamegrendelés típusának kiválasztása",
  DefaultWorkOrderTypeHelp:
    "Állítsa be az alapértelmezett munkamegrendelés típusát az alkalmazásban, az űrlapokon, keresésekben és szűrőkben..",
  ModelType: "Modell típusa",
  CustomerRequests: "Ügyfélkérések",
  ProviderProposals: "Szolgáltatói javaslatok",
  AddNewRequest: "Új kérelem hozzáadása",
  AddNewProposal: "Új ajánlat hozzáadása",
  RequestsAndProposals: "Kérelmek és ajánlatok",
  AppointmentsTodayIntro: "Az Ön mai programálásai.",
  AppointmentsCalendarIntro:
    "Új programálások előkészítése a naptár segítségével.",
  AppointmentGroup: "Programálások csoportja",
  CarModel: "Autómodell",
  CarModelMake: "Autómodell / Autó gyártója",
  SelectOrganization: "Munkapont kiválasztása",
  SelectGroup: "Válassza ki a csoportot",
  IncludeTime: "Tartalmazza az időt",
  InvalidTime: "Érvénytelen idő",
  AppointmentsOverview: "A programálások áttekintése",
  AppointmentsOverviewIntro: "Ezek az utolsó napi programálások",
  AppointmentsCalendarTitle: "Programálási naptár",
  YouHaveNoAppointmentsForToday: "Mára nincsenek programálásai..",
  DeleteAppointmentWarning:
    "Ez <strong>véglegesen</strong> törli a programálást.",
  SignIn: "Bejelentkezés",
  SignInIntroText: "Kérjük, töltse ki az alábbi adatokat",
  DontHaveAnAccount: "Még nincs fiókja?",
  SignUp: "Regisztráljon",
  ForgotPassword: "Elfelejtett jelszó",
  TermsAndConditions: "Felhasználási feltételek",
  PrivacyPolicy: "Adatvédelm",
  LegalDocuments: "Jogi dokumentumok",
  ViewPerGroups: "Csoportonkénti megtekintés",
  IncludeGroupless: "Beleértve a csoport nélkülieket",
  Groupless: "Fără grup",
  SuspendedWorkOrders: "Felfüggesztett munkamegrendelések",
  SuspendedWorkOrdersIntro:
    "Ezek azok a munkamegrendelések, amelyek befejezésük után kifizetésre várnak",
  PinUpdatedConfirmation: "Pin sikeresen frissítve!",
  PasswordChangedConfirmation: "Jelszavát sikeresen frissítettük!",
  GetWorkOrderErrorApi:
    "Hiba lépett fel, miközben megpróbálta lekérni a munkamegrendelés.",
  UpdateWorkOrderErrorApi:
    "Hiba lépett fel, miközben megpróbálta frissíteni a munkamegrendelést.",
  Intake: "Kliens",
  Password: "Jelszó",
  Name: "Név",
  NamePrint: "Nume",
  Price: "Ár",
  Preț: "Ár (ÁFÁ-val)",
  PricePrint: "Preț",
  PricePrintVAT: "Preț (cu TVA)",
  Car: "Autó",
  CarAppointment: "Autó",
  CarPrint: "Auto",
  Duration: "Eltelt idő",
  Time: "Időpont",
  Calendar: "Naptár",
  Reset: "Visszaállítás",
  Filters: "Szürés",
  Username: "Felhasználónév",
  Draft: "Piszkozat",
  Organization: "Munkapont",
  Live: "Élő",
  View: "Megtekintés",
  Previous: "Előző",
  Next: "Következő",
  Totalization: "Öszesités",
  History: "Előzmények",
  Payments: "Fizetés",
  Product: "Termék",
  DailyWorkOrders: "Napi munkarendelések",
  DailyWorkOrdersIntro: "Folyamatban lévő munkarendelések",
  Handheld: "Kézi",
  WorkOrderPrint: "Deviz de lucru",
  YouHaveSwitchedAccountsSuccessfully: "Sikeresen váltottál fiókok között",
  SelectAll: "Összes kiválasztása",
  CompanyReportPdf: "Cégjelentés PDF",
  CompanyReportExcel: "Cégjelentés Excel",
  AdvancedReportPdf: "Speciális Jelentés PDF",
  AdvancedReportExcel: "Speciális Jelentés Excel",
  DailyReportPdf: "Napi Jelentés PDF",
  DailyReportExcel: "Napi Jelentés Excel",
  MonthlyReportPdf: "Havi Jelentés PDF",
  MonthlyReportExcel: "Havi Jelentés Excel",
  WasteReportPdf: "Hulladék Jelentés PDF",
  WasteReportExcel: "Hulladék Jelentés Excel",
  WorkersReportPdf: "Munkavállalók Jelentés PDF",
  WorkersReportExcel: "Munkavállalók Jelentés Excel",

  WorkOrdersProductReportPdf: "Munkarend termék jelentés PDF",
  WorkOrdersProductReportExcel: "Munkarend termék jelentés Excel",
  GenerateWorkOrdersProductReportPdf:
    "Munkarend termék jelentés PDF generálása",
  GenerateWorkOrdersProductReportExcel:
    "Munkarend termék jelentés Excel generálása",
  GenerateWorkOrdersProductReportSuccess:
    "Munkarend termék jelentés sikeresen elkészült",
  GenerateWorkOrdersProductReportExcelSuccess:
    "Munkarend termék jelentés Excel sikeresen elkészült",

  GenerateCompanyReportPdf: "Cégjelentés PDF generálása.",
  GenerateCompanyReportExcel: "Cégjelentés Excel generálása.",
  GenerateAdvancedReportPdf: "Speciális jelentés PDF generálása.",
  GenerateAdvancedReportExcel: "Speciális jelentés Excel generálása.",
  GenerateDailyReportPdf: "Napi jelentés PDF generálása.",
  GenerateDailyReportExcel: "Napi jelentés Excel generálása.",
  GenerateMonthlyReportPdf: "Havi jelentés PDF generálása.",
  GenerateMonthlyReportExcel: "Havi jelentés Excel generálása.",
  GenerateWasteReportPdf: "Hulladék jelentés PDF generálása.",
  GenerateWasteReportExcel: "Hulladék jelentés Excel generálása.",
  GenerateWorkersReportPdf: "Munkavállalók jelentés PDF generálása.",
  GenerateWorkersReportExcel: "Munkavállalók jelentés Excel generálása.",
  GenerateCompanyReportSuccess: "Cégjelentés PDF sikeresen elkészült.",
  GenerateCompanyReportExcelSuccess: "Cégjelentés Excel sikeresen elkészült.",
  GenerateAdvancedReportSuccess: "Speciális jelentés PDF sikeresen elkészült.",
  GenerateAdvancedReportExcelSuccess:
    "Speciális jelentés Excel sikeresen elkészült.",
  GenerateDailyReportSuccess: "Napi jelentés PDF sikeresen elkészült.",
  GenerateDailyReportExcelSuccess: "Napi jelentés Excel sikeresen elkészült.",
  GenerateMonthlyReportSuccess: "Havi jelentés PDF sikeresen elkészült.",
  GenerateMonthlyReportExcelSuccess: "Havi jelentés Excel sikeresen elkészült.",
  GenerateWasteReportSuccess: "Hulladék jelentés PDF sikeresen elkészült.",
  GenerateWasteReportExcelSuccess:
    "Hulladék jelentés Excel sikeresen elkészült.",
  GenerateWorkersReportSuccess:
    "Munkavállalók jelentés PDF sikeresen elkészült.",
  GenerateWorkersReportExcelSuccess:
    "Munkavállalók jelentés Excel sikeresen elkészült.",
  GeneratePdfReportError: "Hiba történt a PDF jelentés generálása közben.",
  GenerateExcelReportError: "Hiba történt az Excel jelentés generálása közben.",
  TotalWorkOrders: "Total work orders",
  AlreadyHaveAnAccount: "Már van fiókja?",
  FullName: "Teljes név",
  ValidationCode: "Ellenőrző kód",
  EmailWithCodeSent:
    "Egy e-mailt elküldtünk Önnek az ellenőrző kóddal. Kérjük, ellenőrizze a beérkező leveleket, és illessze be az ellenőrző kódot a fenti validálási kód mezőbe, hogy folytathassa a regisztrációs folyamatot.",
  RetypePassword: "Írja be újra a jelszót",
  AddData: "Adat hozzáadása",
  UserInfo: "Felhasználói információk",
  CompanyName: "Cég neve",
  VATNumber: "ÁFA szám",
  REGNumber: "REG szám",
  Country: "Ország",
  City: "Város",
  County: "Megye",
  Street: "Utca",
  StreetNumber: "Házszám",
  Website: "Website",
  SuccessfullyRegistered: "Az Ön fiókja sikeresen regisztrálva lett!",
  EditAppointment: "Edit Appointment",
  TyreHotelFooterText:
    "Atenție!!! Pentru anvelopele depozitate la <strong>{{organizationName}}</strong> , răspundem 12 luni! După care automat intră la deșeu!",
  AppointmentDateRangeInfo: "Rendelkezésre álló órák: {{start}} - {{end}}",
  Transfer: "Áthelyezés",
  TransferWorkOrder: "Munkalap áthelyezése",
  TransferingWorkOrder: "Áthelyezés folyamatban",
  AddTyreDimensions: "Add tyre dimensions",
  SelectDateTime: "Időpont",
  AddInBulk: "Tömegesen",
  Add: "Hozzáadása",
  Services: "Szolgáltatások",
  AddProductOrServiceInBulk: "Termék / szolgáltatás hozzáadása",
  CustomizedWorkOrder: "Customized work order",
  CustomizedWorkOrderExplanation: "Add custom information to your work order.",
  DocumentCreator: "Document creator",
  FinalReviewer: "Final reviewer",
  WorkOrderDeadline: "Munkalap határideje",
  WorkOrderExpirationDate: "Munkalap lejárati dátuma",
  WorkOrderMetadata_createdBy: "Document creator",
  WorkOrderMetadata_finalReviewer: "Final reviewer",
  WorkOrderMetadata_deadline: "Munkalap határideje",
  WorkOrderMetadata_ownProduct: "Céges ügyfél",
  WorkOrderMetadata_expirationDate: "Munkalap lejárati dátuma",
  WorkOrderMetadata_smsNotification: "SMS értesítés",
  WorkOrderPdfMetadata_deadline: "<strong>Deadline:</strong> {{deadline}}",
  WorkOrderMetadata_clientRequested: "Az ügyfél határozott kérésére",
  WorkOrderMetadata_withVat: "ÁFA-val",
  WorkOrderMetadata_vatValue: "ÁFA értéke",
  WorkOrderMetadata_vatPercentage: "ÁFA százalék",
  WorkOrderMetadata_clientBringsProducts: "Ugyfél hozza a terméket",
  TyreHotelFooterTextCustom:
    "<p>Depozitarea anvelopelor se face pe o perioadă de 6 luni, iar clientul achită la ridicarea sau înlocuirea anvelopelor suma de:</p><ul><li> 75 de lei - pentru 4 anvelope fară jante </li><li> 95 de lei - pentru 4 anvelope cu jante </li></ul>",
  DateOfTyre: "DOT",
  PrintAll: "Print all",
  PrintAllTooltip: "Print work order + tyre hotel",
  SaveAndPrint: "Mentés és nyomtatás",
  SMSLanguage: "SMS nyelve",
  SelectSMSLanguage: "SMS nyelv kiválasztása",
  CalendarView: "Naptár nézet",
  ThisWeek: "Ez a hét",
  PlateNumberSearchResults: "Rendszám keresési eredmények",
  PlateNumberSearchResultsText:
    "Az alábbi eredmények találhatók erre a rendszámra. Kérjük, válassza ki, melyik adatot szeretné használni.",
  PrefillData: "Kitöltés",
  Selected: "Kiválasztva",
  WithRim: "Felnivel",
  CustomerBringsProducts: "Pangus ügyfél",
  TyreCount: "Count",
  CustomerOrder: "Ügyfél sorrend",
  CustomerOrderIntroText: "Kövesse nyomon és kezelje az ügyfelek sorrendjét.",
  Overview: "Áttekintés",
  CustomerOrderOverviewText: "Ezek a mai vásárlói rendelések.",
  ViewPerGroup: "Megtekintés csoportonként",
  AddNewCustomerOrder: "Új ügyfél sorrend hozzáadása",
  CarLabel: "Autó",
  CreatedByUsername: "Készítette: {{user}}",
  AccountDeletedStatus: "A fiókod jelenleg törlés alatt áll.",
  AccountDeletedInfo:
    "Amint elvégezték, kijelentkezel és minden adatod törlésre kerül.",
  SMSNotification: "SMS értesítés",
  ITPExpirationDatePrint: "Data expirării",
  WorkOrderITPPrint: "Deviz de lucru - ITP",
  SmsReminderSent: "SMS emlékeztető elküldve",
  ReSendSMSNotification: "SMS-értesítés újraküldése",
  Workstation: "Munkaállomás",
  RequestedByClient: "Az ügyfél határozott kérésére",
  RequestedByClientDisclaimer:
    "Elismerem, hogy tájékoztattak a javasolt javítási lehetőségekről, de ragaszkodom ahhoz, hogy a kért javítási módszerrel folytassam.",
  TechnicalDifficultiesError:
    "Hoppá! Jelenleg technikai nehézségekkel küzdünk. Kérjük, próbálja újra később, vagy lépjen kapcsolatba támogató csapatunkkal segítségért.",
  PrintWorkOrderMetadata_createdBy: "Document întocmit de",
  PrintWorkOrderMetadata_finalReviewer: "Inspecția finală",
  PrintWorkOrderPdfMetadata_deadline:
    "<strong>Termenul de execuție:</strong> {{deadline}}",
  RequestedByClientDisclaimerPrint:
    "Recunosc că am fost informat despre opțiunile recomandate de reparație, dar insist să procedez cu metoda de reparație solicitată.",
  PlateNumberPrint: "Nr. de înmatriculare",
  YearPrint: "Anul",
  VinNumberPrint: "Serie șasiu / Număr VIN",
  ClosedAtPrint: "Închis la",
  StatusPrint: "Status",
  ClosedPrint: "Închis",
  CarModelPrint: "Model",
  DownloadPDF: "PDF letöltése",
  DownloadExcel: "Excel letöltése",
  Axis2Tyres4: "2 Tengely 4 Gumiabroncs",
  Axis2Tyres6: "2 Tengely 6 Gumiabroncs",
  Axis3Tyres10: "3 Tengely 10 Gumiabroncs",
  Axis4Tyres12: "4 Tengely 12 Gumiabroncs",
  Axis5Tyres12: "5 Tengely 12 Gumiabroncs",
  AxisAndTyres: "{{axis}} Tengely {{tyres}} Gumiabroncs",
  SignUpIntroText1:
    "Csatlakozz közösségünkhöz és nyisd fel a lehetőségek világát.",
  SignUpIntroText2:
    "Ahhoz, hogy elkezdhess, néhány adatot kell megadnod. Kérlek, add meg teljes neved, e-mail címed és telefonszámod.",
  SignUpIntroText3:
    "Miután kitöltötted a szükséges információkat, elküldünk neked egy megerősítő kódot az e-mail címedre. Egyszerűen add meg a kódot a levélben, hogy megerősítsd az e-mail címedet, és készen állsz arra, hogy felfedezd webazásunk fantasztikus funkcióit és előnyeit.",
  TimeSheet: "Időbeosztás",
  Validation: "Ellenőrzés",
  Validate: "Ellenőrizni",
  Finish: "Befejezni",
  AccessDenied403:
    "Hozzáférés megtagadva! Sajnos Ön nem jogosult erre a műveletre vagy az erőforráshoz való hozzáférésre. Kérjük, forduljon a felhasználói rendszergazdához segítségért.",
  SingleTotal: "Single",
  CommonTotal: "Common",
  Tyre: "Gumiabroncs",
  StepCount: "Lépés {{step}}",
  TyreMake: "Gumiabroncs gyártója",
  TyreModel: "Gumiabroncs modell",
  DiscountOnNewTyre: "Kedvezmény az új gumiabroncsra",
  ColorsLegend: "Színek legendája",
  WithServices: "Szolgáltatásokkal",
  CloseCustomerOrder: "Megrendelés lezárása",
  Worker: "Dolgozó",
  WorkOrdersExcelExport: "Munkamegrendelések Excel exportálása",
  ExportError: "Hiba történt az exportálás során.",
  ExportOfDataInProgress: "Az adatok exportálása folyamatban van.",
  WorkOrdersExportSuccess: "Munkamegrendelések sikeresen exportálva.",
  From: "Tól",
  To: "Ig",
  User: "Felhasználó",
  AppointmentsReportPdf: "Időpontjelentés PDF",
  AppointmentsReportExcel: "Időpontjelentés Excel",
  GenerateAppointmentsReportPdf: "Időpontjelentés PDF generálása.",
  GenerateAppointmentsReportExcel: "Időpontjelentés Excel generálása.",
  GenerateAppointmentsReportSuccess:
    "Az Időpontjelentés PDF sikeresen elkészült.",
  GenerateAppointmentsReportExcelSuccess:
    "Az Időpontjelentés Excel sikeresen elkészült.",
  SMSReport: "SMS Jelentés",
  SMSReportPdf: "SMS Jelentés PDF",
  SMSReportExcel: "SMS Jelentés Excel",
  GenerateSMSReportPdf: "SMS Jelentés PDF generálása.",
  GenerateSMSReportExcel: "SMS Jelentés Excel generálása.",
  GenerateSMSReportSuccess: "Az SMS Jelentés PDF sikeresen elkészült.",
  GenerateSMSReportExcelSuccess: "Az SMS Jelentés Excel sikeresen elkészült.",
  TotalSMS: "Total SMS",
  TotalAppointments: "Összes időpont",
  ClientBringsProducts: "Ügyfél hozza a termékeket",
  ProductsBroughtByClientPrint: "Produse aduse de client",
  Service: "Szolgáltatásaidat",
  ServiceName: "Szolgáltatás neve",

  /**
   * Account settings translations
   */
  withVat: "ÁFA-val",
  vatPercentage: "ÁFA százalék",
  productProfitPercent: "Termék nyereség százalék",
  smsLanguage: "SMS Nyelv",
  ownProductDiscount: "Saját termék kedvezmény",
  itpEnabled: "ITP engedélyezve",
  hideCreateNewTyreHotel: "Új gumiabroncs szálloda elrejtése",
  customLogo: "Egyedi logó",
  appointmentsStart: "Időpontok kezdete",
  appointmentsEnd: "Időpontok vége",
  appointmentsRequired: "Kötelező időpont mezők",
  discountOnNewProduct: "Kedvezmény az új termékre",
  customized: "Személyre szabott",
  woRemoveDiscount: "Kedvezmény eltávolítása a munkalapból",
  woPdfPriceLabelWithVAT: "Ár címke ÁFA-val",
  woPdfTyreTorqueNewLine: "Gumi nyomaték új sor",
  thWarehouseCustom: "Raktár egyedi",
  woPdfCompanySignatureLabelRename: "Cég aláírás címke átnevezése",
  woPdfClientSignatureUnderExecutedServices:
    "Ügyfél aláírása a végrehajtott szolgáltatások alatt",
  woPdfBottomText: "Figyelmeztető szöveg a PDF láblécében",
  hasWoPdfConfig: "Van munkalap PDF konfigurációja",
  woPdfBottomTextTitle: "Figyelmeztető szöveg a PDF láblécében cím",
  woCleanRequestedServices: "Tiszta kért szolgáltatások",
  woPdfHideProductsColumns: "Termék oszlopok elrejtése",
  woAddProductsV2: "Termékek hozzáadása V2",
  YesPrint: "Da",
  NoPrint: "Nu",
  AppointmentsStartTime: "Időpont kezdete",
  AppointmentsEndTime: "Időpont vége",
  AppointmentsStartTimeExample: "Példa: 08:00",
  AppointmentsEndTimeExample: "Példa: 18:00",
  WithGroup: "Csoporttal",
  Slug: "Rövidnév",
  CreatedGuestAppointmentSuccess:
    "Mulțumim pentru programarea unei întâlniri cu noi! Vom lua legătura în curând pentru a confirma programarea dumneavoastră.",
  ConfirmAppointment: "Időpont megerősítése",
  PublicAppointments: "Nyilvános programozás",
  Contacts: "Kapcsolatok",
  EnablePublicCalendar: "Nyilvános ütemezés engedélyezése",
  Address: "Cím",
  NoResultsFound: "Nincs találat",
  Back: "Vissza",
  SuspendedWorkOrdersShort: "Felfüggesztett",
  ReportPdf: "Jelentés PDF",
  GenerateReportPdf: "Jelentés PDF generálása.",
  GenerateReportSuccess: "A jelentés PDF sikeresen elkészült.",
  ReportExcel: "Jelentés Excel",
  GenerateReportExcel: "Jelentés Excel generálása.",
  GenerateReportExcelSuccess: "A jelentés Excel sikeresen elkészült.",
  ExcludeDates: "Kizárt napok a nyilvános naptárból",
  ExcludeSaturdays: "Szombatok kizárása",
  Preview: "Előnézet",
  Close: "Bezárás",
  GoToWorkOrder: "Munkalap megtekintése",
  SMSMessages: "SMS üzenetek",
  SMSMessagesIntroText: "Személyre szabott SMS üzenetek",
  Message: "Üzenet",
  MessageType: "Üzenet típusa",
  SMSMessageExplanation:
    '<p>Saját SMS-üzeneteit a következő változók használatával hozhatja létre, amelyek konkrét információkat helyettesítenek:</p> <ul> <li>$organizationName: Ezt a szervezet neve váltja fel.</li> <li>$from: Ezt a találkozó dátuma váltja fel.</li> <li>$address: Ezt a szervezet címe váltja fel.</li> </ul> <p>Példa üzenet: "Önnek időpontja van a $organizationName-nél $from-kor, cím: $address".</p>',
  SMSMessageExplanationWithITP:
    '<p>Saját SMS-üzeneteit a következő változók használatával hozhatja létre, amelyek konkrét információkat helyettesítenek:</p> <ul> <li>$organizationName: Ezt a szervezet neve váltja fel.</li> <li>$from: Ezt a találkozó dátuma váltja fel.</li> <li>$address: Ezt a szervezet címe váltja fel.</li> <li>$plateNumber: Ezt az autó rendszámával fogjuk helyettesíteni. </li> </ul> <p>Példa üzenet: "Önnek időpontja van a $organizationName-nél $from-kor, cím: $address".</p>',
  SMSMessageAppointmentCreated: "Időpont létrehozva",
  SMSMessageAppointmentNotification: "Időpont értesítés",
  SMSMessageAppointmentDeleted: "Időpont törölve",
  SMSMessageITPNotification: "ITP értesítés",
  Characters: "Karakterek",
  CreditsRequired: "{{credits}} Kreditek szükségesek.",
  CreditRequired: "1 Kredit szükséges.",
  CreditsExample: "2 Kreditek.",
  Credit: "Kredit.",
  WorkOrderProducts: "Munkalap termékek",
  WorkOrderProductsReport: "Munkalap termékek jelentése",
  TotalUM: "Total buc",
  isService: "Szolgáltatás",
  isTyre: "Gumiabroncs",
  PageNotFound: "Az oldal nem található",
  SuccessMessage: "Sikeres üzenet",
  AppointmentsBreakInterval: "Időpontok szünete",
  AppointmentsBreakIntervalExample: "Példa: 13:00-14:00",
  Available: "Elérhető",
  Unavailable: "Nem elérhető",
  Reserved: "Foglalt",
  RecalculateDiscount: "Kedvezmény újraszámítása",
  NewGuestAppointmentsDoubleClick:
    "Kattintson duplán az időpont kiválasztásához",
  SimpleService: "Egyszerű szolgáltatás",

  /**
   * Socket event translations
   */
  WORK_ORDER_CREATED: "Létrehozott munkamegrendelés",
  WORK_ORDER_UPDATED: "Munkamegrendelés frissítése",
  WORK_ORDER_DELETED: "Munkamegrendelés törlése",
  LOYALTY_CARD_CREATED: "Hűségkártya létrehozása",
  LOYALTY_CARD_UPDATED: "Hűségkártya frissítése",
  LOYALTY_CARD_DELETED: "Hűségkártya törlése",
  CLIENT_CREATED: "Új ügyfél létrehozása",
  CLIENT_UPDATED: "Ügyfél frissítése",
  CLIENT_DELETED: "Ügyfél törlése",
  PRODUCT_CREATED: "Új termék létrehozása",
  PRODUCT_UPDATED: "Termék frissítése",
  PRODUCT_DELETED: "Termék törlése",
  TYREHOTEL_CREATED: "Új abroncshotel létrehozása",
  TYREHOTEL_UPDATED: "Gumiabroncshotel frissítve",
  TYREHOTEL_DELETED: "Gumiabroncshotel törölve",
  APPOINTMENTS_CREATED: "New appointment created",
  APPOINTMENTS_UPDATED: "Appointment updated",
  APPOINTMENTS_DELETED: "Appointment deleted",

  /**
   * Dynamic translations
   */
  "[fieldValue] not equal with [hasToMatch]":
    "{{fieldValue}} nem egyenlő a {{hasToMatch}}",
  "At least [min]": "Legalább {{min}} karakterek.",
  "At least [minLength]": "Legalább {{minLength}} karakterek.",
  "At most [max]": "Legalább {{max}} karakter.",
  "At most [maxLength]": "Legtöbb {{maxLength}} karakter.",
  "No support for your screen size [width]x[height]":
    "Jelenleg nem nyújtunk támogatást az Ön képernyőméretéhez (<strong>{{width}}</strong>x<strong>{{height}}</strong>).",
  "Please type [keyWord] to confirm":
    "Kérjük, írja be a <strong>{{keyWord}}</strong> megerősítéshez.",
  "This work order was created [timeElapsed]":
    "Ez a munkarendelés létrejött {{timeElapsed}}.",
  "This work order was updated [timeElapsed]":
    "Ez a munkarendelés frissítve lett{{timeElapsed}}.",
  "Member since [time]": "Tag <strong>{{time}}<strong/>.,..-óta",
  "Account level: [level]": "Fiók szint: <strong>{{level}}<strong />.",
  "Username: [username]": "Felhasználónév: <strong>{{username}}<strong />.",
  "Your next billing is due: [dueDate]":
    "A következő számlája esedékes: <strong>{{dueDate}}<strong />.",
  "Hello [user], your account is currently suspended":
    "Hello <strong>{{user}}</strong>, az Ön fiókja jelenleg felfüggesztésre került.",

  /**
   * Filter fields
   */
  "filter.field_account_id": "Fiók",
  "filter.field_name": "Név",
  "filter.field_email": "E-mail",
  "filter.field_first_name": "Keresztnév",
  "filter.field_last_name": "Vezetéknév",
  "filter.field_timesheet_status": "Státusz",
  "filter.field_total": "Összesen",
  "filter.field_uuid": "Munkamegrendelés",
  "filter.field_discount": "Kedvezmény",
  "filter.field_products": "Termékek",
  "filter.field_quick_text": "Keresés",
  "filter.field_subtotal": "Részösszeg",
  "filter.field_username": "Felhasználónév",
  "filter.field_type": "Számla típusa",
  "filter.field_tyre_rim": "Gumiabroncs felni",
  "filter.field_finished": "Véglegesítve",
  "filter.field_is_service": "Szolgáltatás",
  "filter.field_car.model": "Autómodell",
  "filter.field_company.email": "E-mail",
  "filter.field_tyre_name": "Gumiabroncs neve",
  "filter.field_car_type_id": "Autó típusa",
  "filter.field_fidelity": "Hűséges ügyfél",
  "filter.field_vin_number": "VIN szám",
  "filter.field_tyre_width": "Gumiabroncs szélessége",
  "filter.field_totalization": "Összesítve",
  "filter.field_client_name": "Ügyfél neve",
  "filter.field_created_at": "Létrehozás dátuma",
  "filter.field_is_company": "Jogi személy",
  "filter.field_car_number": "Rendszám",
  "filter.field_tyre_height": "Gumiabroncs magassága",
  "filter.field_card_number": "Kártyaszám",
  "filter.field_is_expendable": "Leírható",
  "filter.field_plate_number": "Rendszám",
  "filter.field_delegate_name": "Küldött neve",
  "filter.field_discount_type": "Kedvezmény típusa",
  "filter.field_organization_id": "Munkapont",
  "filter.field_payment_type_id": "Fizetés típusa",
  "filter.field_discount_value": "Kedvezmény értéke",
  "filter.field_car_plate_number": "Rendszám",
  "filter.field_company.vat_number": "Adószám",
  "filter.field_tyreService.tyre_rim": "Gumiabroncs felni",
  "filter.field_is_count_price": "Aktivált ár",
  "filter.field_car_manufacturer": "Autó gyártója",
  "filter.field_car.make": "Autó gyártója",
  "filter.field_tyreService.tyre_width": "Gumiabroncs szélessége",
  "filter.field_work_order_type_id": "Munkamegrendelés típusa",
  "filter.field_tyreService.tyre_height": "Gumiabroncs magassága",
  "filter.field_company.reg_number": "Cégjegyzékszám",
  "filter.field_created_by": "Létrehozta",
  "filter.field_status": "Státusz",
  "filter.field_user_id": "Felhasználó",
  "filter.field_start_date": "Kezdeti dátum",
  "filter.field_end_date": "Befejezés dátuma",
  "filter.field_appointment_groups": "Programálás csoport",
  "filter.field_itp": "ITP",
  "filter.field_car_make": "Autó gyártója",
  "filter.field_car_model": "Autómodell",
  "filter.field_is_tyre": "Gumiabroncs",
  "filter.field_discount_on_new_tyre": "Kedvezmény az új gumiabroncsra",
  "filter.field_workers": "Dolgozó",
  "filter.field_from": "Intervallum kezdete",
};
