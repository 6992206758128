/**
 * External imports
 */
import { camelCase } from "lodash";

/**
 * Imports hooks
 */
import { useSelector, useTranslation, useUserUtils, useUtils } from "../index";

/**
 * Imports constants
 */
import {
  ITP_WORK_ORDER_TYPE_VALUE,
  DEFAULT_DATE_FORMAT,
} from "../../constants";

/**
 * Import types
 */
import { FilterModel } from "../../types";
import { CreateFilterProps } from "./useFilterModelsUtils.types";

/**
 * Returns utility functions to easily build filter models
 * Provides state management for filters
 */
export const useFilterModelsUtils = () => {
  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets utility functions
   */
  const {
    upperFirst,
    snakeStringToCamel,
    formatDate,
    isObject,
    normalizeDate,
    camelStringToSnake,
    dateToString,
    dateRangeToString,
  } = useUtils();

  /**
   * Gets the user utility functions
   */
  const {
    getOrganizationById,
    getWorkOrderTypeById,
    getUserById,
    getCarTypeById,
    getPaymentTypeById,
    getProductById,
    isUserAdmin,
    getWorkerById,
  } = useUserUtils();

  /**
   * Gets the account state
   */
  const { accountInformation } = useSelector((state) => state.account);

  /**
   * Returns a filter model
   */
  const createFilter = (props: CreateFilterProps) => {
    const { field, selected, type, selectedOnly, displayOnly } = props;

    return {
      field,
      options: [],
      order: 1,
      selected,
      type,
      displayOnly: displayOnly || false,
      selectedOnly: selectedOnly || false,
    } as FilterModel;
  };

  /**
   * Checks if the filter can be removed from the active filters
   */
  const isDeleteAllowed = (filter: FilterModel) => {
    const isAdmin = isUserAdmin();
    const { field, disableDelete } = filter;

    if (disableDelete) return false;

    if (field === "filter.field_organization_id") {
      return isAdmin;
    }

    return true;
  };

  /**
   * Handles formatting the filter value
   */
  const formatFilterValue = (filter: FilterModel) => {
    if (filter.field === "status" && filter.selected === "closed") {
      return t("Closed");
    }

    if (
      filter.field === "status" &&
      (filter.selected as string).toUpperCase() === filter.selected
    ) {
      return t(upperFirst(camelCase(filter.selected.toLowerCase())));
    }

    if (filter.field === "workers") {
      const worker = getWorkerById(filter.selected);

      return worker ? worker.name : filter.selected;
    }

    if (filter.field === "organization_id") {
      const organization = getOrganizationById(filter.selected);

      if (filter.selected === "all") return t("All");

      return organization ? organization.name : filter.selected;
    }

    if (filter.field === "work_order_type_id") {
      if (filter.selected === ITP_WORK_ORDER_TYPE_VALUE) return t("ITP");

      const workOrderType = getWorkOrderTypeById(filter.selected);

      return workOrderType ? workOrderType.name : filter.selected;
    }

    if (filter.field === "account_id") {
      return accountInformation.name;
    }

    if (filter.field === "created_by" || filter.field === "user_id") {
      const user = getUserById(filter.selected);
      return user ? user.username : filter.selected;
    }

    if (
      filter.field === "finished" ||
      filter.field === "created_at" ||
      filter.field === "start_date" ||
      filter.field === "end_date" ||
      filter.field === "from"
    ) {
      if (typeof filter.selected === "string") {
        return dateToString(filter.selected);
      }

      return dateRangeToString(filter.selected);
    }

    if (
      filter.field === "totalization" ||
      filter.field === "is_company" ||
      filter.field === "fidelity" ||
      filter.field === "is_service" ||
      filter.field === "is_expendable" ||
      filter.field === "is_count_price" ||
      filter.field === "itp" ||
      filter.field === "is_tyre" ||
      filter.field === "discount_on_new_tyre"
    ) {
      return filter.selected ? t("Yes") : t("No");
    }

    if (filter.field === "timesheet_status") {
      const value = filter.selected as string;
      return t(upperFirst(snakeStringToCamel(value.toLocaleLowerCase())));
    }

    if (filter.field === "payment_type_id") {
      const paymentType = getPaymentTypeById(filter.selected);
      return paymentType ? paymentType.name : filter.selected;
    }

    if (filter.field === "car_type_id") {
      const carType = getCarTypeById(filter.selected);
      return carType ? carType.name : filter.selected;
    }

    if (filter.field === "products") {
      const ids: number[] = filter.selected;
      let text = "";

      ids.forEach((id, index) => {
        const foundProduct = getProductById(id);

        if (foundProduct) {
          text += foundProduct.name;
        }

        if (index !== ids.length - 1) text += ", ";
      });

      return text;
    }

    return filter.selected;
  };

  /**
   * Handles formatting the filter label
   */
  const formatFilterLabel = (filter: FilterModel) => {
    if (filter.field.includes("filter.field_")) return filter.field;

    const translationPrefix = "filter.field_";
    return translationPrefix + filter.field;
  };

  /**
   * Handles formatting the filter field
   */
  const formatFilterField = (field: string) => {
    if (field.includes("filter.field_")) return field;

    const translationPrefix = "filter.field_";
    return translationPrefix + field;
  };

  /**
   * Handles formatting the active filters
   */
  const formatActiveFilters = (filters: FilterModel[]) => {
    return filters.map((filter) => {
      const filterField = formatFilterLabel(filter);
      const filterSelectValue = formatFilterValue(filter);

      /**
       * Defines the new filter model
       */
      const newFilter: FilterModel = {
        ...filter,
        field: filterField,
        selected: filterSelectValue,
      };

      return newFilter;
    });
  };

  /**
   * Returns the field type
   */
  const getFieldType = (field: string) => {
    if (field.includes("tyreService")) return "equals";
    if (field.includes("_id")) return "dropdown";
    if (field.includes("is_")) return "boolean";
    if (field.includes("discount_on_new_tyre")) return "boolean";
    if (field === "finished" || field === "created_at" || field === "from")
      return "range";
    if (field === "start_date") return "gte";
    if (field === "end_date") return "lte";
    if (field === "itp") return "boolean";
    return "like";
  };

  /**
   * Returns the filter fields
   */
  const getFilterFields = (filters: FilterModel[]) => {
    return filters.map((filter) => filter.field);
  };

  /**
   * Handles finding a filter by the field value
   */
  const getFilterByField = (field: string, filters: FilterModel[]) => {
    return filters.find((f) => f.field === field);
  };

  /**
   * Handles Adding or replacing a new filter
   */
  const addOrReplaceFilter = (
    newFilter: FilterModel,
    filters: FilterModel[],
  ) => {
    const filterFields = getFilterFields(filters);
    const hasField = filterFields.includes(newFilter.field);
    const fieldIndex = hasField ? filterFields.indexOf(newFilter.field) : -1;

    const updatedFilters = filters.map((filter, index) => {
      if (hasField && index === fieldIndex) {
        return newFilter;
      }

      return filter;
    });

    if (!hasField && newFilter.selected) {
      updatedFilters.push(newFilter);
    }

    const validFilters = updatedFilters.filter((filter) => {
      if (filter.type === "boolean") return true;
      return !!filter.selected;
    });
    return validFilters;
  };

  /**
   * Handles removing a filter
   */
  const removeFilter = (filter: FilterModel, filters: FilterModel[]) => {
    return filters.filter((f) => {
      return formatFilterLabel(f) !== formatFilterLabel(filter);
    });
  };

  /**
   * Handles removing a filter by field
   */
  const removeFilterByField = (field: string, filters: FilterModel[]) => {
    return filters.filter((f) => {
      return formatFilterLabel(f) !== formatFilterField(field);
    });
  };

  /**
   * Checks if the field is selected
   */
  const isFieldSelected = (value: any) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }

    return value !== "" && value !== undefined && value !== null;
  };

  /**
   * Normalizes input values
   */
  const normalizeInputValue = (value: any, field: string) => {
    if (
      (field === "finished" ||
        field === "created_at" ||
        field === "start_date" ||
        field === "from" ||
        field === "end_date") &&
      value &&
      value.length > 0
    ) {
      const dates = value.split(" - ");

      if (dates.length === 1) return formatDate(normalizeDate(dates[0]));

      return [
        formatDate(normalizeDate(dates[0]), DEFAULT_DATE_FORMAT),
        formatDate(normalizeDate(dates[1]), DEFAULT_DATE_FORMAT),
      ];
    }

    /**
     * If product name will be needed instead of ids @todo - Check with Szabi
     */
    // if (field === "products" && value && value.length > 0) {
    //   return value.map((id: number) => {
    //     const product = getProductById(id);
    //     return product ? product.name : id;
    //   });
    // }

    return value;
  };

  /**
   * Normalizes field values
   */
  const normalizeFilterValue = (value: any, field: string) => {
    if (
      (field === "finished" || field === "created_at" || field === "from") &&
      Array.isArray(value) &&
      value.length === 2
    ) {
      const dateFormat = "dd/MM/yyyy";
      const startDate = formatDate(normalizeDate(value[0]), dateFormat);
      const endDate = formatDate(normalizeDate(value[1]), dateFormat);

      return `${startDate} - ${endDate}`;
    }

    return value;
  };

  /**
   * Converts filter models to inputs
   */
  const modelsToInputs = (filters: FilterModel[]) => {
    /**
     * Initializes the input entries
     */
    const inputEntries: { [key: string]: any } = {};

    filters.forEach((filter) => {
      const key = snakeStringToCamel(filter.field.replace("tyreService.", ""));
      const value = normalizeFilterValue(filter.selected, filter.field);

      inputEntries[key] = value;
    });

    return inputEntries;
  };

  /**
   * Converts filter models to graphQL query variables
   */
  const modelsToQueryVariables = (filters: FilterModel[]) => {
    /**
     * Initializes the variable entries
     */
    const variableEntries: { [key: string]: any } = {};

    filters.forEach((filter) => {
      variableEntries[filter.field] = filter.selected;
    });

    return variableEntries;
  };

  /**
   * Converts form inputs to filter models
   */
  const inputsToModels = (data: any, strict?: boolean) => {
    /**
     * Initializes the models
     */
    const models: FilterModel[] = [];

    /**
     * Initializes nested entries
     */
    let nestedEntries: any[][] = [];

    /**
     * Defines the field entries
     */
    const fieldEntries = Object.entries(data)
      .map((entry) => {
        let field = camelStringToSnake(entry[0]);
        let value = normalizeInputValue(entry[1], field);

        if (isObject(value) && !Array.isArray(value)) {
          const entries = Object.entries(value).map((entry) => {
            let nestedField = `${field}.${camelStringToSnake(entry[0])}`;
            let nestedValue = normalizeInputValue(entry[1], field);

            return [nestedField as string, nestedValue as any];
          });

          nestedEntries = entries;
        }

        if (!strict && field.includes("tyre_")) {
          field = "tyreService." + field;
        }

        if (!strict && field.includes("car_")) {
          switch (field) {
            case "car_make":
              field = "car.make";
              break;
            case "car_model":
              field = "car.model";
              break;
            default:
              break;
          }
        }

        if (field.includes("card_number")) {
          value = value.replaceAll("-", "");
        }

        return [field as string, value as any];
      })
      .filter((entry) => {
        const value = entry[1];

        if (isObject(value) && !Array.isArray(value)) return false;
        return true;
      });

    /**
     * Fills up the models
     */
    fieldEntries.concat(nestedEntries).forEach((entry) => {
      const field = entry[0];
      const selected = entry[1];
      const type = getFieldType(field);

      if (
        isFieldSelected(selected) &&
        field !== "client_id" &&
        field !== "totalization" &&
        field !== "is_count_price" &&
        field !== "workers"
      ) {
        models.push(createFilter({ field, selected, type }));
      }

      if (field === "totalization") {
        models.push(createFilter({ field, selected, type, displayOnly: true }));
      }

      if (isFieldSelected(selected) && field === "workers") {
        models.push(
          createFilter({
            field,
            selected,
            type: "has",
            displayOnly: false,
          }),
        );
      }

      if (field === "is_count_price") {
        models.push(
          createFilter({ field, selected, type, selectedOnly: true }),
        );
      }
    });

    return models;
  };

  /**
   * Handles sanitizing the totalized filters
   */
  const sanitizeTotalizedFilters = (filters: FilterModel[]) => {
    return filters.filter((filter) => {
      const clientFilter = filter.field.includes("client");
      const delegateFitler = filter.field.includes("delegate");

      if (clientFilter || delegateFitler) return false;
      return true;
    });
  };

  const parseQueryValue = (key: string, value: string) => {
    if (key.includes("is_") || key === "itp") return value === "true";
    if (key.includes("_id")) return parseFloat(value);
    return value;
  };

  const filtersToQueryString = (filters: FilterModel[]) => {
    const queryObject = filters.reduce((acc, filter) => {
      if (filter.selected !== undefined) {
        // @ts-ignore
        acc[filter.field] = filter.selected;
      }
      return acc;
    }, {});

    return new URLSearchParams(queryObject).toString();
  };

  return {
    filtersToQueryString,
    createFilter,
    formatFilterValue,
    formatFilterLabel,
    normalizeFilterValue,
    formatActiveFilters,
    modelsToInputs,
    modelsToQueryVariables,
    inputsToModels,
    getFilterFields,
    getFilterByField,
    addOrReplaceFilter,
    removeFilter,
    removeFilterByField,
    isDeleteAllowed,
    sanitizeTotalizedFilters,
    parseQueryValue,
    getFieldType,
  };
};
