import { useCallback, useMemo } from "react";

/**
 * External imports
 */
import {
  cloneDeep,
  findIndex,
  orderBy,
  uniqBy,
  range,
  toInteger,
  upperFirst,
  toSafeInteger,
} from "lodash";
import { addDays } from "date-fns";

/**
 * Imports Material UI components
 */
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import PaymentOutlinedIcon from "@mui/icons-material/PaymentOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";

/**
 * Imports hooks
 */
import {
  useTranslation,
  useUtils,
  useAccountSettings,
  useSelector,
  useActions,
} from "../index";
import { useTheme } from "@mui/material";

/**
 * Imports types
 */
import {
  User,
  Account,
  UserRole,
  AccountUser,
  AvatarOption,
  SelectOption,
  WorkOrderType,
  UserOrganization,
} from "../../types";
import { BadgeOption } from "../../components/InputSelectBadge";

/**
 * Provides utility functions for getting / transforming user related data
 */
export const useUserUtils = () => {
  /**
   * Gets the translator
   */
  const { t } = useTranslation();

  /**
   * Gets the auth state
   */
  const auth = useSelector((state) => state.auth);

  /**
   * Gets the account state
   */
  const {
    roles,
    workers,
    carTypes,
    carNames,
    products,
    carModels,
    wheelTypes,
    paymentTypes,
    accountUsers,
    activePackage,
    organizations,
    workOrderTypes,
    productsHashMap,
    appointmentGroups,
    accountInformation,
  } = useSelector((state) => state.account);

  /**
   * Gets the clients state
   */
  const { clients, clientsHashMap } = useSelector((state) => state.clients);

  /**
   * Gets the actions
   */
  const { updateAuth, updateAccountUsers, _updateUserAccountInformation } =
    useActions();

  const { defaultWorkOrderType } = useSelector((state) => state.workOrder);

  /**
   * Gets the theme
   */
  const theme = useTheme();

  /**
   * Gets the account settings
   */
  const { ownProductDiscount } = useAccountSettings();

  /**
   * Gets utility functions
   */
  const { formatDate, formatPaymentTypeName } = useUtils();

  /**
   * Returns the car name options
   */
  const getCarNameOptions = useCallback(() => {
    if (carNames.length < 1) return [];

    return carNames.map((carName) => ({
      label: carName.name,
      value: carName.id,
    }));

    // eslint-disable-next-line
  }, [carNames]);

  /**
   * Returns the car model options
   */
  const getCarModelOptions = useCallback(
    (carMake?: string) => {
      if (carModels.length < 1) return [];

      if (carMake && typeof carMake === "string") {
        const foundCarMake = carNames.find(
          (carName) => carName.name === carMake,
        );

        if (foundCarMake) {
          return carModels
            .filter(
              (carModel) =>
                carModel.makeId.toString() === foundCarMake.id.toString(),
            )
            .map((carModel) => ({
              label: carModel.name,
              value: carModel.name,
            }));
        }
      }

      return carModels.map((carModel) => ({
        label: carModel.name,
        value: carModel.name,
      }));
    },
    // eslint-disable-next-line
    [carModels],
  );

  /**
   * Handles getting the user organization
   */
  const getUserOrganization = (userOrganizations?: UserOrganization[]) => {
    if (auth.user) {
      const { organizationId } = auth.user;
      const searchPool = userOrganizations || organizations;

      const foundOrganization = searchPool.find(
        (org) => org.id === organizationId,
      );

      return foundOrganization;
    }
  };

  /**
   * Handles getting a user by id
   */
  const getUserById = (id: number | string) => {
    if (accountUsers && accountUsers.length > 0) {
      return accountUsers.find((user) => user.id === id);
    }
  };

  /**
   * Handles getting a user by username
   */
  const getUserByUsername = (username: string) => {
    if (accountUsers && accountUsers.length > 0) {
      return accountUsers.find((user) => user.username === username);
    }
  };

  /**
   * Handles calculating the due date
   */
  const getBillingDueDate = () => {
    if (accountInformation.activeFrom && accountInformation.activeTo) {
      const activeTo = addDays(new Date(accountInformation.activeTo), 1);

      return formatDate(activeTo, "d MMMM, yyyy");
    }
  };

  /**
   * Defines the palette colors
   */
  const palette = [
    "primary.20.80",
    "primary.40.80",
    "primary.60.80",
    "primary.80.60",
    "primary.100.60",
    "primary.120.60",
    "primary.140.60",
    "primary.160.60",
    "secondary.20.80",
    "secondary.40.80",
    "secondary.60.80",
    "secondary.80.60",
    "secondary.100.60",
    "secondary.120.60",
    "secondary.140.60",
    "secondary.160.60",
    "scheduled.20.80",
    "scheduled.40.80",
    "scheduled.60.80",
    "scheduled.80.60",
    "scheduled.100.60",
    "scheduled.120.60",
    "scheduled.140.60",
    "scheduled.160.60",
    "info.20.80",
    "info.40.80",
    "info.60.80",
    "info.80.60",
    "info.100.60",
    "info.120.60",
    "info.140.60",
    "info.160.60",
    "success.20.80",
    "success.40.80",
    "success.60.80",
    "success.80.60",
    "success.100.60",
    "success.120.60",
    "success.140.60",
    "success.160.60",
    "error.20.80",
    "error.40.80",
    "error.60.80",
    "error.80.60",
    "error.100.60",
    "error.120.60",
    "error.140.60",
    "error.160.60",
    "base.text.dark",
    "base.gray.grayDark",
    "base.grayLight.gray",
  ];

  /**
   * Returns the user avatar options (background colors)
   */
  const getUserAvatarOptions = useMemo(() => {
    const options: AvatarOption[] = [];

    palette.forEach((paletteColor) => {
      const [palette, color, hover] = paletteColor.split(".");

      options.push({
        color: (theme.palette as any)[palette][color],
        hover: (theme.palette as any)[palette][hover],
      });
    });

    return options;
  }, [palette]);

  /**
   * Returns the user products
   */
  const getUserProducts = useMemo(() => {
    return products.length > 0 ? products : [];
  }, [products]);

  /**
   * Handles getting an organization by id
   */
  const getOrganizationById = (id: number | string) => {
    if (organizations && organizations.length > 0) {
      return organizations.find((organization) => organization.id === id);
    }
  };

  /**
   * Handles getting a payment type by id
   */
  const getPaymentTypeById = (id?: number | string) => {
    if (paymentTypes && paymentTypes.length > 0 && id) {
      return paymentTypes.find((paymentType) => paymentType.id === id);
    }
  };

  /**
   * Handles getting a car type by id
   */
  const getCarTypeById = (id: number | string) => {
    if (carTypes && carTypes.length > 0) {
      return carTypes.find(
        (carType) => carType.id.toString() === id.toString(),
      );
    }
  };

  /**
   * Handles getting a client by id
   * O(1) time
   */
  const getClientById = (id: number | string) => {
    if (Object.keys(clientsHashMap).length > 0) {
      return clientsHashMap[id];
    }

    if (clients && clients.length > 0) {
      return clients.find((client) => client.id === id);
    }
  };

  /**
   * Handles getting a product by id
   * O(1) time
   */
  const getProductById = (id: number | string) => {
    if (Object.keys(productsHashMap).length > 0) {
      return productsHashMap[id];
    }

    if (products && products.length > 0) {
      return products.find((product) => product.id === id);
    }
  };

  /**
   * Handles getting a work order type by id
   */
  const getWorkOrderTypeById = (id: number | string) => {
    if (workOrderTypes && workOrderTypes.length > 0) {
      return workOrderTypes.find(
        (workOrderType) => workOrderType.id === toInteger(id),
      );
    }
  };

  /**
   * Handles getting an appointment group by id
   */
  const getAppointmentGroupById = (id: number | string) => {
    if (appointmentGroups && appointmentGroups.length > 0) {
      return appointmentGroups.find((group) => group.id === toInteger(id));
    }
  };

  /**
   * Returns the appointment group name
   */
  const getAppointmentGroupName = (id: string | number) => {
    const appointmentGroup = getAppointmentGroupById(id);

    if (id === -1 || id == null) return t("Groupless");

    return appointmentGroup ? appointmentGroup.name : "-";
  };

  /**
   * Handles getting a wheel type by id
   */
  const getWheelTypeById = (id?: number | string) => {
    if (wheelTypes && wheelTypes.length > 0 && id) {
      return wheelTypes.find((wheelType) => wheelType.id === id);
    }
  };

  /**
   * Handles getting a worker by id
   */
  const getWorkerById = (id: number | string) => {
    if (workers && workers.length > 0) {
      return workers.find((worker) => worker.id === id);
    }
  };

  /**
   * Returns the new tyre value translated
   */
  const getNewTyreValue = (newTyre?: boolean, waste?: number | string) => {
    if (newTyre && waste) {
      return `${t("Yes")} (${t("Waste")}: ${waste})`;
    }
    return newTyre ? t("Yes") : t("No");
  };

  /**
   * Handles getting a client by name
   */
  const getClientByName = (name: string) => {
    if (clients && clients.length > 0) {
      return clients.find((client) => client.name === name);
    }
  };

  /**
   * Gets a car type by name
   */
  const getCarTypeByName = (name: string) => {
    if (carTypes && carTypes.length > 0) {
      return carTypes.find((carType) => carType.name?.indexOf(name) > -1);
    }
  };

  /**
   * Gets a wheel type by name
   */
  const getWheelTypeByName = (name: string) => {
    if (wheelTypes && wheelTypes.length > 0) {
      return wheelTypes.find((wheelType) => wheelType.name === name);
    }
  };

  /**
   * Gets a work order type by name
   */
  const getWorkOrderTypeByName = (name: string, types?: WorkOrderType[]) => {
    const list = types || workOrderTypes;

    if (list && list.length > 0) {
      return list.find((workOrderType) => workOrderType.name === name);
    }
  };

  /**
   * Checks if the account is active
   */
  const isAccountActive = useMemo(
    () => accountInformation.status === "active",
    [accountInformation.status],
  );

  /**
   * Checks if the account is suspended
   */
  const isAccountSuspended = useMemo(
    () => accountInformation.status === "suspended",
    [accountInformation.status],
  );

  /**
   * Checks if the user is an admin
   */
  const isUserAdmin = () => {
    if (!auth.user) return false;

    return auth.user.type === "admin";
  };

  /**
   * Checks if the user is an operator
   */
  const isUserOperator = () => {
    if (!auth.user) return false;

    return auth.user.type === "operator";
  };

  /**
   * Checks if the user is a worker
   */
  const isUserWorker = useMemo(() => {
    if (!auth.user) return false;

    return auth.user.type === "worker";
  }, [auth.user]);

  const isUserSimpleService = useMemo(() => {
    if (!auth.user) return false;

    return auth.user.type === "simple_service";
  }, [auth.user]);

  /**
   * Returns organizations to be used in select inputs
   */
  const getOrganizationOptions = (allowSelectAll?: boolean) => {
    if (organizations.length < 1) return [];

    const options = organizations.map((organization) => {
      return {
        label: organization.name,
        value: organization.id,
      } as SelectOption;
    });

    if (allowSelectAll) {
      options.unshift({
        label: t("SelectAll"),
        value: "all",
      });
    }

    return options;
  };

  /**
   * Returns appointment groups to be used in select inputs
   */
  const getAppointmentGroupOptions = () => {
    if (appointmentGroups.length < 1) return [];

    return appointmentGroups.map((group) => {
      return {
        label: group.name,
        value: group.id,
      } as SelectOption;
    });
  };

  /**
   * Returns appointment groups by organization to be used in select inputs
   */
  const getAppointmentGroupByOrgOptions = (organizationId: number) => {
    if (appointmentGroups.length < 1) return [];

    return appointmentGroups
      .filter((group) => {
        return toInteger(group.organizationId) === organizationId;
      })
      .map((group) => {
        return {
          label: group.name,
          value: group.id,
        } as SelectOption;
      });
  };

  /**
   * Returns the createdBy options
   */
  const getCreatedByOptions = useMemo(() => {
    const filteredUsers = accountUsers.filter((user) => {
      return roles.includes(user.type);
    });

    return filteredUsers.map((accountUser) => {
      const { username, firstName, lastName } = accountUser;

      let label = username;
      let value = username;

      if (firstName && lastName) {
        label = `${firstName} ${lastName}`;
      }

      return {
        label,
        value,
      } as SelectOption;
    });
  }, [accountUsers]);

  /**
   * Returns account users to be used in select inputs
   */
  const getAccountUserOptions = (roles?: UserRole[]) => {
    if (accountUsers.length < 1) return [];

    if (roles && roles.length > 0) {
      const filteredUsers = accountUsers.filter((user) => {
        return roles.includes(user.type);
      });

      return filteredUsers.map((accountUser) => {
        const { id, username } = accountUser;

        return {
          label: username,
          value: id,
        } as SelectOption;
      });
    }

    return accountUsers.map((accountUser) => {
      return {
        label: accountUser.username,
        value: accountUser.id,
      } as SelectOption;
    });
  };

  /**
   * Returns the discount type options
   */
  const getDiscountTypeOptions: SelectOption[] = [
    { label: t("DiscountTypePercentage"), value: "PERCENT" },
    { label: t("DiscountTypeValue"), value: "VALUE" },
  ];

  /**
   * Returns the product types to be used in select inputs
   */
  const getProductTypeOptions: SelectOption[] = [
    { label: t("Product"), value: "product" },
    { label: t("Service"), value: "service" },
    { label: t("Expendable"), value: "expendable" },
  ];

  /**
   * Returns work order types to be used in select inputs
   */
  const getWorkOrderTypeOptions = useMemo(() => {
    if (workOrderTypes.length < 1) return [];

    return workOrderTypes
      .map((workOrderType) => {
        return {
          label: workOrderType.name,
          value: workOrderType.id,
        } as SelectOption;
      })
      .filter((option) => option.label !== "Spalatorie"); // Bring this back when we have this service available
  }, [workOrderTypes]);

  /**
   * Returns the car type name
   */
  const getCarTypeName = (carTypeId: string | number) => {
    const carType = getCarTypeById(carTypeId);
    return carType ? carType.name : "";
  };

  /**
   * Returns the wheel type name
   */
  const getWheelTypeName = (wheelTypeId: string | number) => {
    const wheelType = getWheelTypeById(wheelTypeId);
    return wheelType ? wheelType.name : "";
  };

  /**
   * Returns car types to be used in select inputs
   */
  const getCarTypeOptions = useMemo(() => {
    if (carTypes.length < 1) return [];

    return carTypes.map((carType) => {
      return {
        label: carType.name,
        value: carType.id,
      } as SelectOption;
    });
  }, [carTypes]);

  /**
   * Returns wheel types to be used in select inputs
   */
  const getWheelTypeOptions = useMemo(() => {
    if (wheelTypes.length < 1) return [];

    return wheelTypes.map((wheelType) => {
      return {
        label: wheelType.name,
        value: wheelType.id,
      } as SelectOption;
    });
  }, [wheelTypes]);

  /**
   * Returns an array of clients to be used in select inputs
   */
  const getClientOptions = useCallback(() => {
    if (clients.length < 1) return [];

    return clients
      .map((client) => {
        return {
          label: client.name,
          value: client.id,
        } as SelectOption;
      })
      .concat(getNoOptions);

    // eslint-disable-next-line
  }, [clients]);

  /**
   * Returns an array of products to be used in select inputs
   */
  const getProductsOptions = useCallback(
    (filter?: string) => {
      if (products.length < 1) return [];

      if (filter) {
        const options = products
          .filter((product) => {
            if (filter === "service") {
              return product.isService;
            }

            if (filter === "product") {
              return !product.isService;
            }

            if (filter === "expendable") {
              return product.isExpendable;
            }

            return false;
          })
          .map((product) => {
            return {
              label: product.name,
              value: product.id,
            } as SelectOption;
          })
          .concat(getNoOptions);

        /**
         * Orders alphabetically
         */
        return orderBy(uniqBy(options, "label"), "label");
      }

      return products
        .map((product) => {
          return {
            label: product.name,
            value: product.id,
          } as SelectOption;
        })
        .concat(getNoOptions);

      // eslint-disable-next-line
    },
    [products],
  );

  /**
   * Returns the default empty option
   */
  const getNoOptions = [
    {
      label: "",
      value: "",
    } as SelectOption,
  ];

  /**
   * Returns an array of workers to be used in select inputs
   */
  const getWorkersOptions = useMemo(() => {
    if (workers.length < 1) return getNoOptions;

    /**
     * Defines the select options
     */
    const options = workers.map((worker) => {
      return {
        label: worker.name,
        value: worker.id,
      } as SelectOption;
    });

    /**
     * Orders alphabetically
     */
    return orderBy(options, "label");
  }, [workers]);

  /**
   * Returns an array of operators to be used in select inputs
   */
  const getOperatorsOptions = useMemo(() => {
    if (accountUsers.length < 1) return getNoOptions;

    /**
     * Defines the select options
     */
    const options = accountUsers
      .filter(
        (user) =>
          (user.type === "operator" || user.type === "admin") && user?.onLists,
      )
      .map((user) => {
        const { firstName, lastName, username, id } = user;

        let label = username;
        let value = id;

        if (firstName && lastName) {
          label = `${firstName} ${lastName}`;
        }

        return {
          label,
          value,
        } as SelectOption;
      });

    /**
     * Orders alphabetically
     */
    return orderBy(
      options?.length ? options : [{ value: "", label: "" }],
      "label",
    );
  }, [accountUsers]);

  /**
   * Returns payment types to be used in select inputs
   */
  const getPaymentTypeOptions = useMemo(() => {
    if (paymentTypes.length < 1) return [];

    return uniqBy(
      paymentTypes.map((paymentType) => {
        return {
          label: paymentType.name,
          value: paymentType.id,
        } as SelectOption;
      }),
      "label",
    );
  }, [paymentTypes]);

  /**
   * Returns the org users to be used in select inputs
   */
  const getOrganizationUsersOptions = useMemo(() => {
    if (accountUsers.length < 1) return [];

    return accountUsers.map((user) => {
      return {
        label: user.username,
        value: user.id,
      } as SelectOption;
    });
  }, [accountUsers]);

  /**
   * Returns years to be used in select inputs
   */
  const getYearOptions = () => {
    const max = new Date().getUTCFullYear();
    const min = max - 80;

    return range(min, max + 1)
      .sort()
      .reverse()
      .map((year) => {
        return {
          label: year.toString(),
          value: year,
        } as SelectOption;
      });
  };

  /**
   * Returns the user's full name
   */
  const getUserFullName = (firstName?: string, lastName?: string) => {
    if (firstName && !lastName) return firstName;
    if (!firstName && lastName) return lastName;

    return `${firstName} ${lastName}`;
  };

  /**
   * Returns the user's initials
   */
  const getUserInitials = (user?: AccountUser) => {
    if (user) {
      const firstName = user.firstName || "";
      const lastName = user.lastName || "";

      if (firstName) return upperFirst(firstName[0]);
      if (lastName) return upperFirst(lastName[0]);

      return user.username.substring(0, 2).toUpperCase();
    }

    const firstName = accountInformation.firstName || "";
    const lastName = accountInformation.lastName || "";

    if (firstName) return upperFirst(firstName[0]);
    if (lastName) return upperFirst(lastName[0]);

    return "";
  };

  /**
   * Returns the color of the work order status badge
   */
  const getPaymentTypeOptionIcon = (name: string) => {
    switch (name) {
      case "Card":
        return <PaymentOutlinedIcon />;
      case "Cash":
        return <AccountBalanceWalletOutlinedIcon />;
      case "Fact":
      case "Fact Termeni":
        return <ReceiptLongOutlinedIcon />;
      case "Fisa":
      case "Fisa Termeni":
        return <ReceiptOutlinedIcon />;
      default:
        return <PaymentsOutlinedIcon />;
    }
  };

  /**
   * Returns the payment type options to be used in select inputs - type badge
   */
  const getPaymentTypeBadgeOptions = useMemo(() => {
    return getPaymentTypeOptions.map((option) => {
      /**
       * Formats the label
       */
      const label = formatPaymentTypeName(option.label);

      return {
        ...option,
        color: "default",
        label: label,
        icon: getPaymentTypeOptionIcon(label),
      } as BadgeOption;
    });
  }, [getPaymentTypeOptions]);

  /**
   * Returns the organization address
   * Computes the full address from what information is available.
   */
  const getOrganizationAddress = (address?: UserOrganization["address"]) => {
    if (address && Object.keys(address).length > 0) {
      const { fullAddress } = address;

      /**
       * Builds the address parts
       */
      const street = address.street ? `Str. ${address.street}` : "";
      const number = address.number ? `Nr. ${address.number}` : "";
      const city = address.city ? `Ors. ${address.city}` : "";
      const county = address.county ? `Jud. ${address.county}` : "";

      if (!street && !number && !city && !county) {
        return { location: fullAddress };
      }

      return { location: `${street} ${number} ${city} ${county}` };
    }

    return { location: "" };
  };

  /**
   * Returns the user organization data;
   */
  const getUserOrganizationData = useCallback(() => {
    if (organizations.length > 0) {
      const organization = getUserOrganization(organizations);

      if (organization) {
        const { company } = organization;
        const { location } = getOrganizationAddress(organization.address);

        return {
          name: company.name,
          taxNumber: company.vatNumber,
          regNumber: company.regNumber,
          location: location,
          phone: company.phone,
          email: company.email,
        };
      }
    }
    // eslint-disable-next-line
  }, [accountInformation]);

  /**
   * Returns the active package
   */
  const getActivePackage = () => {
    if (activePackage) return activePackage;
  };

  /**
   * Returns the default work order type
   */
  const getDefaultWorkOrderType = () => {
    if (defaultWorkOrderType) {
      return getWorkOrderTypeById(defaultWorkOrderType);
    }

    return getWorkOrderTypeByName("Vulcanizare");
  };

  /**
   * Returns the default work order type
   */
  const getDefaultWorkOrderTypeITP = () => {
    return getWorkOrderTypeByName("Service Auto");
  };

  /**
   * Returns the account levels
   */
  const getAccountLevel = () => {
    if (roles.length < 1) return "worker";
    if (roles.includes("admin")) return "admin";
    if (roles.includes("operator")) return "operator";
    if (roles.includes("simple_service")) return "simple_service";

    return "worker";
  };

  const accountLevel: UserRole = useMemo(() => {
    if (roles.length < 1) return "worker";
    if (roles.includes("admin")) return "admin";
    if (roles.includes("operator")) return "operator";
    if (roles.includes("simple_service")) return "simple_service";

    return "worker";
  }, [roles]);

  /**
   * Returns the account levels
   */
  const getAccountLevelOptions = () => {
    const options: SelectOption[] = [
      { label: t("Administrator"), value: "admin" },
      { label: t("Worker"), value: "worker" },
      { label: t("Operator"), value: "operator" },
      { label: t("SimpleService"), value: "simple_service" },
    ];

    return options;
  };

  /**
   * Handles updating the account users
   */
  const updateExistingUser = (userId: number, user: AccountUser) => {
    const clonedUsers = cloneDeep(accountUsers);
    const index = findIndex(clonedUsers, {
      id: userId,
    });

    if (index !== -1) {
      clonedUsers[index] = user;
      updateAccountUsers(clonedUsers);
    }
  };

  /**
   * Handles updating the user account
   */
  const updateUserAccount = (user: AccountUser) => {
    /**
     * Builds the updated account data structure
     */
    const updatedData = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      avatarConfig: user.avatarConfig,
    };

    /**
     * Clones the existing auth state
     */
    const clonedAuth = cloneDeep(auth);

    /**
     * Clones the auth user
     */
    const authUser = cloneDeep(auth.user!);

    /**
     * Updates the auth user state
     */
    clonedAuth.user = { ...authUser, ...updatedData };

    /**
     * Updates the auth state if the same user is the one that's logged in
     */
    if (authUser.id === user.id) {
      updateAuth(clonedAuth);
    }

    _updateUserAccountInformation({ ...accountInformation, ...updatedData });
  };

  /**
   * Handles building the user account data
   */
  const buildUserAccountData = (account: Account) => {
    const { user } = auth;

    if (user) {
      /**
       * Gets the user data from the account users
       */
      const foundUser = account.users.find(
        (user) => user.username === auth.user?.username,
      );

      /**
       * Defines the user account data
       */
      const userAccount: User = {
        id: account.id,
        name: account.name,
        uuid: account.uuid,
        status: account.status,
        username: user.username,
        activeTo: account.activeTo,
        createdAt: account.createdAt,
        activeFrom: account.activeFrom,
        description: account.description,
        email: foundUser ? foundUser.email : "",
        lastName: foundUser ? foundUser.lastName : "",
        firstName: foundUser ? foundUser.firstName : "",
        organization: getUserOrganization(account.organizations),
        organizations: account.organizations,
        avatarConfig: foundUser ? foundUser.avatarConfig : undefined,
        lastTimeSheet: foundUser ? foundUser.lastTimeSheet : undefined,
      };

      return userAccount;
    }
  };

  /**
   * Returns the account sms options
   */
  const getAccountSmsOptions: SelectOption[] = [
    { label: "HU", value: "HU" },
    { label: "RO", value: "RO" },
  ];

  /**
   * Returns the discount value with the account discount applied
   */
  const applyAccountDiscount = (total: number) => {
    const discount = Math.round((total * ownProductDiscount) / 100);

    return toSafeInteger(discount);
  };

  /**
   * Returns the user avatar config
   */
  const getUserAvatarConfig = (userId: number) => {
    const user = getUserById(userId);

    if (user) {
      return user.avatarConfig;
    }
  };

  /**
   * Returns the avatar background color
   */
  const getUserAvatarColor = useMemo(() => {
    if (accountInformation.avatarConfig)
      return accountInformation.avatarConfig.color;

    return theme.palette.primary.main;
  }, [accountInformation.avatarConfig]);

  /**
   * Handles formatting the current user name
   */
  const formatCurrentUserName = () => {
    const { username, firstName, lastName } = accountInformation;

    if (!firstName && !lastName) return username;
    if (firstName && !lastName) return `${username} (${firstName})`;
    if (!firstName && lastName) return `${username} (${lastName})`;

    return `${username} (${firstName} ${lastName})`;
  };

  return {
    isUserAdmin,
    getUserById,
    isUserWorker,
    getNoOptions,
    getClientById,
    getWorkerById,
    isUserOperator,
    getProductById,
    getCarTypeById,
    getYearOptions,
    getCarTypeName,
    isAccountActive,
    getUserProducts,
    getNewTyreValue,
    getAccountLevel,
    getClientByName,
    getUserInitials,
    getUserFullName,
    applyAccountDiscount,
    getCarTypeByName,
    getClientOptions,
    getWheelTypeById,
    getWheelTypeName,
    getActivePackage,
    getCarNameOptions,
    getCarTypeOptions,
    getWorkersOptions,
    getBillingDueDate,
    getUserByUsername,
    updateUserAccount,
    getCarModelOptions,
    getWheelTypeByName,
    getProductsOptions,
    isAccountSuspended,
    getPaymentTypeById,
    updateExistingUser,
    getCreatedByOptions,
    getWheelTypeOptions,
    getOperatorsOptions,
    getUserOrganization,
    getOrganizationById,
    buildUserAccountData,
    getWorkOrderTypeById,
    getUserAvatarOptions,
    getPaymentTypeOptions,
    getAccountUserOptions,
    getProductTypeOptions,
    getWorkOrderTypeByName,
    getOrganizationOptions,
    getDiscountTypeOptions,
    getOrganizationAddress,
    getAccountLevelOptions,
    getWorkOrderTypeOptions,
    getDefaultWorkOrderType,
    getUserOrganizationData,
    getAppointmentGroupById,
    getAppointmentGroupName,
    getPaymentTypeBadgeOptions,
    getOrganizationUsersOptions,
    getAccountSmsOptions,
    getDefaultWorkOrderTypeITP,
    getAppointmentGroupByOrgOptions,
    getUserAvatarConfig,
    getUserAvatarColor,
    formatCurrentUserName,
    accountLevel,
    isUserSimpleService,
  };
};
