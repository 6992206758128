import { LanguageResource } from "./index";

/**
 * Defines the english to english translations
 */
export const en_ro: LanguageResource = {
  /**
   * General
   */
  VAT: "TVA",
  VATPrint: "TVA",
  PriceBase: "Preț de bază",
  AddA: "Adăugați un",
  Home: "Acasă",
  LoadData: "Încarca datele",
  HideData: "Ascunde datele",
  ShowData: "Afișează datele",
  AssociatedWith: "Asociat cu",
  AddNewWorker: "Adăugați un muncitor nou",
  BirthDate: "Data nașterii",
  Account: "Cont",
  AccountSettings: "Setările contului",
  AccountStatus: "Statusul contului",
  AccountSummary: "Rezumatul contului",
  AccountType: "Tipul de cont",
  ActionCannotBeUndone: "Această acțiune <strong>nu poate fi anulată</strong>.",
  Activate: "Activați",
  ActivePackage: "Pachet activ",
  AddNewCarType: "Adăugați un nou tip de mașină",
  AddNewClient: "Adăugați un nou client",
  AddNewCondition: "Adăugați o nouă condiție",
  AddNewContact: "Adăugați un nou contact",
  AddNewLoyaltyCard: "Adăugați un nou card de fidelitate",
  AddNewOrganization: "Adăugați un puct de lucru nou",
  AddNewProduct: "Adăugați un nou produs",
  AddNewTyreHotel: "Adăugați un nou hotel de anvelope",
  AddNewPaymentMethod: "Adăugați o nouă metodă de plată",
  AddNewAppointmentGroup: "Adăugați un nou grup de programări",
  AddNewUser: "Adăugați un nou utilizator",
  AddNewGuaranty: "Adăugați o nouă garanție",
  AddNewTimesheet: "Adăugați o nouă foaie de pontaj",
  AddNewAppointment: "Adăugați o nouă programare",
  AddProductOrService: "Adăugați un produs / serviciu",
  AddTyreHotel: "Adăugați hotel de anvelope",
  Advanced: "Avansat",
  AdvancedReport: "Raport avansat",
  AdvancedReports: "Raport avansat",
  AppointmentsReport: "Raport programări",
  SuspendedWoReport: "Raport comenzi suspendate",
  All: "Toate",
  Appearance: "Aspect",
  Appointment: "Programare",
  AppointmentGroups: "Grupuri de programare",
  Appointments: "Programări",
  AppointmentsIntroText: "Gestionați și urmăriți programările.",
  AreYouAbsolutelySure: "Sunteți absolut sigur?",
  AreYouARobot: "Sunteți un robot?",
  AreYouSure: "Sunteți sigur?",
  AvailableModulesForAccount:
    "Modulele disponibile pentru contul dumneavoastră:",
  BackToDashboard: "Înapoi la tabloul de bord",
  BeforeYouSuspendTheAccount:
    "Înainte de a suspenda contul asociat cu <strong> {{account}} </strong>, vă rugăm să rețineți:",
  BiggerThan: "Mai mare decât",
  BiggerThanOrEqual: "Mai mare decât sau egal cu",
  BoltTorque: "Cuplul de torsiune al șuruburilor (Nm)",
  BoltTorqueShort: "Cuplu",
  BoltTorquePrint: "Cuplu de strangere (Nm)",
  CalculatingDynamicPricing: "Calcularea prețurilor dinamice.",
  Cancel: "Anulați",
  CardActive: "Card activ",
  CarData: "Date despre mașină",
  CardNumber: "Numărul cardului",
  CarType: "Tipul de mașină",
  CarTypePrint: "Tipul de mașină",
  CarTypes: "Tipuri de mașini",
  CarTypesIntroText: "Gestionați și urmăriți tipurile de mașini.",
  AppointmentGroupsIntroText: "Adăugați și gestionați grupurile de programări.",
  ChangeLanguage: "Modificarea limbii utilizate",
  ChangePassword: "Schimbați parola",
  CheckYourNotifications: "Verificați notificările dvs. pentru detalii.",
  Client: "Client",
  ClientCode: "Cod client",
  ClientData: "Date client",
  ClientDataPrint: "Date client",
  ClientName: "Numele clientului",
  Clients: "Clienți",
  ClientsIntroText: "Gestionați și urmăriți clienții dumneavoastră.",
  ClientType: "Tipul de client",
  ClientTypePrint: "Tipul de client",
  CloseAccount: "Închideți contul",
  CloseAccountInfo:
    "Închiderea contului dvs. va elimina definitiv datele și conținutul.",
  Closed: "Închis",
  Company: "Companie",
  CompanyData: "Datele companiei",
  CompanyMonthlyReportsTotalization:
    "Totalizarea rapoartelor lunare ale companiei",
  CompanyMonthlyReportTotalized: "Totalizarea rapoartelor lunare",
  CompanyReport: "Raportul companiei",
  Condition: "Condiție",
  ConfirmNewPassword: "Confirmați noua parolă",
  ContactAdminToExtendPackage:
    "Vă rugăm să contactați administratorul punctului dvs. de lucru pentru a vă extinde pachetul actual.",
  ContactData: "Date de contact",
  Contracts: "Contracte",
  Copyright: "© {{year}} VehiGo. Toate drepturile rezervate",
  CopyrightShort: "© {{year}}",
  Create: "Creați",
  CreateITP: "Creați ITP",
  CreatedAt: "Data creării",
  UpdatedAt: "Data actualizării",
  CreatedTime: "Ora creării",
  CreateTyreHotel: "Creați hotelul de anvelope",
  CreateWorkOrders: "Creați ordine de lucru",
  CreatingTyreHotel: "Crearea unui hotel de anvelope",
  CurrentMonth: "Luna curentă",
  CurrentPassword: "Parolă curentă",
  CurrentStatus: "Status curent",
  CurrentWeek: "Săptămâna curentă",
  CustomizeAppearance:
    "Personalizarea modului în care arată aplicația pe dispozitivul dvs.",
  Daily: "Zilnic",
  DailyReport: "Raport zilnic",
  Dashboard: "Tablou de bord",
  DashboardPageIntro:
    "Creați, gestionați și urmăriți comenzile clienților dvs.",
  Date: "Data",
  DatePrint: "Dată",
  DateRange: "Intervalul de date",
  Deactivate: "Dezactivare",
  Delegate: "Delegat",
  DelegateName: "Numele delegatului",
  DelegateNamePrint: "Numele delegatului",
  Delete: "Șterge",
  DeleteAllNotifications: "Ștergeți toate notificările",
  DeleteClientWarning:
    "Acest lucru va șterge <strong>permanent</strong> clientul.",
  DeleteInProgress: "Ștergere în curs",
  DeleteLoyaltyCardWarning:
    "Acest lucru va șterge <strong>permanent</strong> cardul de fidelitate..",
  DeleteNotificationsWarning: "Sunteți pe cale să ștergeți toate notificările.",
  DeleteTimesheetWarning:
    "Acest lucru va șterge <strong>permanent</strong> foaia de pontaj.",
  DeleteOrganizationWarning:
    "Acest lucru va șterge <strong>permanent</strong> punctul de lucru.",
  DeleteProductWarning:
    "Acest lucru va șterge <strong>permanent</strong> produsul.",
  DeleteServiceWarning:
    "Acest lucru va șterge <strong>permanent</strong> acest serviciu.",
  DeleteThisProductWarning:
    "Acest lucru va șterge <strong>permanent</strong> acest produs.",
  DeleteTyreHotelWarning:
    "Acest lucru va șterge <strong>permanent</strong> hotelul de anvelope.",
  DeleteUserWarning:
    "Acest lucru va șterge <strong>permanent</strong> utilizatorul.",
  DeleteAccountWarning:
    "Acest lucru va șterge <strong>permanent</strong> contul.",
  DeleteWorkerWarning:
    "Acest lucru va șterge <strong>permanent</strong> muncitorul.",
  DeleteWorkOrderWarning:
    "Acest lucru va șterge <strong>permanent</strong> comanda de lucru.",
  DeleteGuarantyWarning:
    "Acest lucru va șterge <strong>definitiv </strong>definitiv garanția.",
  DeletingTyreHotel: "Ștergerea hotelului de anvelope",
  DeletingWorkOrder: "Ștergerea ordinului de lucru",
  DeletingTimesheet: "Ștergerea foii de pontaj",
  DepositTyreHotel: "Depozit / Hotel de anvelope",
  Description: "Descriere",
  DescriptionPrint: "Descriere",
  DetailedReport: "Raport detaliat",
  Discount: "Reducere",
  DiscountPrint: "Reducere",
  DiscountType: "Tipul de reducere",
  DiscountTypePercentage: "Procentaj",
  DiscountTypeValue: "Valoarea (RON)",
  DiscountValue: "Valoarea reducerii",
  DistanceDone: "Distanța parcursă (km)",
  DocumentPrefix: "Prefixul documentului",
  DocumentSuffix: "Sufixul documentului",
  Done: "Efectuat",
  DownloadAsPDF: "Descărcați în format PDF",
  DropHereToDelete: "Apăsați aici pentru a șterge",
  ITPForm: "Formular ITP",
  Edit: "Editați",
  EditITP: "Editați ITP",
  EditStatus: "Statusul editării",
  EditClient: "Editați clientul",
  EditLoyaltyCard: "Editați cardul de fidelitate",
  EditProduct: "Editați produsul",
  EditService: "Editare serviciu",
  EditTyreHotel: "Editare hotel de anvelope",
  ViewTyreHotel: "Vizualizare hotel de anvelope",
  EditWorkOrder: "Editare comandă de lucru",
  EditWorkOrderITP: "Editare ITP",
  EditGuarantee: "Editați garanția",
  EditWorkOrderIntro: "Actualizați o comandă rapid și ușor.",
  EditDraftWorkOrder: "Editați schița de comandă de lucru",
  EditDraftWorkOrderIntro:
    "Actualizați rapid și ușor o schiță de comandă de lucru.",
  EditCustomerOrder: "Editați comanda clientului",
  CreateWorkOrder: "Creați o comandă de lucru",
  ElapsedTime: "Timp scurs",
  Email: "Email",
  en_en: "RO",
  en_enLabel: "Engleză",
  en_hu: "HU",
  en_huLabel: "Maghiară",
  en_ro: "RO",
  en_roLabel: "Română",
  EntityType: "Tipul de entitate",
  Equals: "Egal",
  Expendable: "Consumabile",
  ExpiresIn: "Expiră în",
  Export: "Exportare",
  Field: "Câmp",
  FirstName: "Prenume",
  LastName: "Nume de familie",
  FlatPriceExplanation: "Noul preț este același cu prețul standard..",
  ForClient: "Pentru client",
  ForWarehouse: "Pentru depozit",
  General: "Generalități",
  GeneralData: "Date generale",
  GenerateCardNumber: "Generarea numărului de card",
  GettingProductAndServices: "Obținerea de produse și servicii.",
  GettingWorkOrderData: "Obținerea de date privind ordinele de lucru",
  GroupByDay: "Gruparea pe zile",
  HigherPriceExplanation: "Noul preț este mai mare decât prețul standard..",
  HotelOrDeposit: "Hotel / Depozit",
  InProgress: "În curs",
  InvalidEmail: "E-mail invalid",
  InvalidNumber: "Număr invalid",
  InvalidPlateNumber: "Număr de înmatriculare invalid",
  IUnderstandDeleteCard: "Am înțeles, ștergeți acest card.",
  IUnderstandDeleteClient: "Am înțeles, ștergeți acest client.",
  IUnderstandDeleteHotel: "Am înțeles, ștergeți acest hotel de anvelope.",
  IUnderstandDeleteOrganization: "Am înțeles, ștergeți acest punct de lucru.",
  IUnderstandDeleteProduct: "Am înțeles, ștergeți acest produs.",
  IUnderstandDeleteUser: "Am înțeles, ștergeți acest utilizator.",
  IUnderstandDeleteAccount: "Am înțeles, ștergeți acest cont.",
  IUnderstandDeleteWorker: "Am înțeles, ștergeți acest lucrător.",
  IUnderstandDeleteWorkOrder: "Am înțeles, ștergeți acest ordin de lucru.",
  IUnderstandDeleteGuaranty: "Am înțeles, ștergeți această garanție.",
  IUnderstandDeleteTimesheet: "Am înțeles, ștergeți această foaie de pontaj.",
  Language: "Limba",
  LegalEntity: "Persoană juridică",
  LessThan: "Mai puțin de",
  LessThanOrEqual: "Mai puțin sau egal cu",
  LoadingOptions: "Opțiuni de încărcare",
  Location: "Locație",
  Logout: "Deconectare",
  LowerPriceExplanation: "Noul preț este mai mic decât prețul standard..",
  LoyalClient: "Client fidel",
  LoyalClients: "Clienți fideli",
  LoyaltyCard: "Card de fidelitate",
  LoyaltyCardPrint: "Card de fidelitate",
  LoyaltyCards: "Carduri de fidelitate",
  LoyaltyCardsIntroText: "Gestionați și urmăriți cardurile de fidelitate.",
  Management: "Management",
  Manufacturer: "Producător",
  MarkAllAsRead: "Marcați totul ca citit",
  MarkAsRead: "Marcați ca citit",
  MatchingCardFound: "S-a găsit cardul corespunzător",
  MessageBoxError: "Eroare",
  MessageBoxInfo: "Info",
  MessageBoxSuccess: "Succes",
  MessageBoxWarning: "Avertizare",
  MessageShownInError:
    "Dacă credeți că acest mesaj este afișat din greșeală, ne puteți contacta folosind butonul de mai jos",
  Min1Worker: "Min. 1 muncitor",
  Model: "Model",
  ModelPrint: "Model",
  Monthly: "Lunar",
  MonthlyReport: "Raport lunar",
  MonthlyTotalOrders: "Total comenzi lunare",
  New: "Nou",
  Skipped: "Omis",
  NewPassword: "Parolă nouă",
  NewPrice: "Preț nou",
  NewTyre: "Pneu nou",
  NewTyres: "Anvelope noi",
  NewTyresPrint: "Anvelope noi",
  NewVersionAvailable:
    "Este disponibilă o versiune mai nouă. Vă rugăm să actualizați",
  NewWorkOrder: "Noua comandă de lucru",
  ITP: "ITP",
  ITPIntroText: "Creați rapid și ușor o comandă de lucru ITP.",
  NewWorkOrderIntro: "Creați rapid și ușor o comandă de client.",
  NextStep: "Pasul următor",
  No: "Nu",
  NoBarcode:
    "Ne pare rău, nu a fost detectat niciun cod de bare. Vă rugăm să încercați din nou sau introduceți manual codul de bare de mai jos.",
  NoNewNotifications: "Nu aveți notificări noi.",
  NoOptions: "Nu există opțiuni",
  Options: "Opțiuni",
  NoScanResultsPleaseTryAgain:
    "Nu s-au găsit carduri. Vă rugăm să încercați din nou.",
  NoSearchResultsFound: "Nu s-au găsit rezultate.",
  NotFound: "Nu s-a găsit",
  Notifications: "Notificări",
  NotificationsIntroText: "Gestionați și urmăriți notificările dvs.",
  NoUnreadNotifications: "Nu aveți notificări necitite.",
  Observations: "Observații",
  Observation: "Observație",
  ObservationsPrint: "Observații",
  OnHold: "În așteptare",
  OpenNotifications: "Deschide notificarile",
  OrderNumber: "Număr de comandă",
  OrderNumberPrint: "Număr de comandă",
  Orders: "Comenzi",
  Organizations: "Puncte de lucru",
  OrganizationsIntroText: "Gestionați și urmăriți punctele dvs. de lucru.",
  PastMonth: "Luna trecută",
  PastWeek: "Săptămâna trecută",
  Payment: "Plată",
  PaymentMethods: "Metode de plată",
  PaymentType: "Tipul de plată",
  PaymentTypes: "Tipuri de plată",
  Person: "Persoană",
  Phone: "Telefon",
  PhoneFix: "Telefon fix",
  PhoneMobile: "Telefon mobil",
  PhoneNumber: "Număr de telefon",
  PhoneNumberPrint: "Număr de telefon",
  PickDateRange: "Alegeți un interval de date",
  PlateNumber: "Nr. de înmatriculare",
  PleaseCompleteTheRecaptcha: "Vă rugăm să completați recaptcha",
  PleaseContactAccountAdmin:
    "Vă rugăm să contactați administratorul contului dvs. pentru a vă recupera contul.",
  PleaseProvideAtLeastOneFilter: "Vă rugăm să furnizați cel puțin un filtru",
  PleaseUseBiggerScreen:
    "Vă rugăm să folosiți un ecran cu o lățime de cel puțin <strong> 1100 pixeli </strong>, ne cerem scuze pentru neplăceri.",
  PostCode: "Codul poștal",
  PreviousStep: "Etapa anterioară",
  PriceActivated: "Preț activat",
  PriceConditions: "Condiții de preț",
  Print: "Imprimare",
  ProcessingYourRequest: "Procesarea cererii dvs.",
  ProductIsInactive:
    "Prețul acestui produs este dezactivat. Acest lucru poate fi modificat în Setări stație de lucru / Produse",
  ProductName: "Denumirea produsului",
  Products: "Produse",
  ProductsPrint: "Produse",
  ProductsAndServices: "Produse / Servicii",
  ProductsIntroText: "Gestionați și urmăriți produsele dumneavoastră.",
  ServicesIntroText: "Gestionați și urmăriți serviciile dumneavoastră.",
  ProductType: "Tipul de produs",
  Quantity: "Cantitate",
  QuantityPrint: "Cantitate",
  QueryInterval: "Intervalul de interogare",
  RecoverAccount: "Recuperează contul",
  RecoverAccountInfo:
    "Contul dvs. este în prezent suspendat, prin urmare, unele caracteristici nu sunt disponibile.",
  RecoverAccountRestoreFeatures:
    "Puteți să vă recuperați contul pentru a restabili caracteristicile în orice moment.",
  RecoveringYourAccount: "Recuperarea contului dumneavoastră",
  RegNumber: "J/F",
  ReleaseToDelete: "Eliberați pentru a șterge",
  RemoveThisNotification: "Eliminați această notificare",
  Reports: "Rapoarte",
  ReportsPageIntroText: "Textul introductiv al paginii Rapoarte",
  ReportTitle: "Titlul raportului",
  RequestTimeout: "Cererea dvs. a expirat",
  RequiredField: "Câmp obligatoriu",
  ResetFormWarning: "Sunteți pe cale să resetați formularul.",
  Save: "Salvați",
  SaveDraft: "Salvați schița",
  SavedSuccessfully: "Salvat cu succes",
  Saving: "Salvare",
  ScanCard: "Scanare card",
  Scheduled: "În așteptare",
  SelectAProduct: "Selectați un produs",
  SelectAService: "Selectați un serviciu",
  SelectExpendable: "Selectați consumabile",
  Settings: "Setări",
  Shipments: "Expedieri",
  ShortName: "Denumire scurtă",
  ShowResults: "Afișați rezultatele",
  SmartTableResultsCount: "rezultate",
  SomeFeaturesNoLongerAvailable:
    "Unele caracteristici nu vor mai fi disponibile",
  SortOrder: "Comandă",
  SortOrderField: "Câmp",
  Sta: "Standard",
  Status: "Status",
  StillFetchingProductsPleaseTryAgain:
    "Ne pare rău! Încă vă căutăm produsele și serviciile. Vă rugăm să încercați din nou.",
  Subtotal: "Subtotal",
  SubtotalPrint: "Subtotal",
  Summary: "Rezumat",
  Support: "Suport",
  SuspendAccount: "Suspendați contul",
  SuspendAccountInfoReviewed:
    "Am analizat informațiile de mai sus. Sunt pregătit să îmi suspend contul.",
  SuspendAccountNotPermanent: "Suspendarea contului dvs. nu este permanentă.",
  SuspendAccountReactivate:
    "Puteți să îl reactivați în orice moment, iar datele dvs. vor rămâne în cont cât timp acesta este inactiv.",
  SuspendAvailableFeatures:
    "Doar setările contului vor fi disponibile pentru conturile de administrator, în scopul reactivării contului suspendat.",
  SuspendImpactsUsers:
    "<strong>{{userCount}} utilizatorii vor fi afectați.</strong>",
  SuspendingYourAccount: "Suspendarea contului dumneavoastră",
  StandardPrice: "Preț standard: <strong>{{price}}</strong>",
  Switch: "Comutator",
  SwitchAccount: "Schimbați contul",
  Syncronizing: "Sincronizare",
  TaskCompletion: "Finalizarea sarcinii",
  TaxAndRegistration: "CUI/ J/F",
  TaxNumber: "CUI",
  Timesheet: "Foaie de pontaj",
  TimesheetExample:
    "Ex: L-V 08:00 - 18:00 | Sâmbăta 08:00 - 13:00 | Duminică închis",
  Timesheets: "Fișe de pontaj",
  TimesheetsIntroText:
    "Gestionați și urmăriți foaia de pontaj a angajaților dumneavoastră.",
  Today: "Astăzi",
  Total: "Total",
  TotalVAT: "Total (cu TVA)",
  TotalPrint: "Total",
  TotalPrintVAT: "Total (cu TVA)",
  TotalOrders: "Total comenzi",
  TotalQuantity: "Cantitate totală",
  TotalReport: "Raport total",
  Type: "Tip",
  TyreData: "Date despre anvelope",
  TyreDimensions: "Dimensiunile anvelopei",
  TyreDimensionsPrint: "Dimensiunile anvelopei",
  TyreDimensionsShort: "Dimensiuni anvelope",
  TyreHeight: "Înălțimea anvelopei",
  TyreHeightShort: "Înălțime",
  TyreHotel: "Hotel de anvelope",
  TyreHotels: "Hoteluri de anvelope",
  TyreHotelsIntroText: "Gestionați și urmăriți hotelul dvs. de anvelope.",
  TyreName: "Nume anvelope",
  TyrePressure: "Presiunea pneurilor (Bar)",
  TyrePressureShort: "Presiune",
  TyrePressurePrint: "Presiunea pneurilor (Bar)",
  TyreQuantity: "Cantitatea de anvelope",
  TyreRim: "Janta anvelopei",
  TyreRimShort: "Diametru",
  TyreService: "Service pentru anvelope",
  TyreServicePackageDescription:
    "Acest pachet conține toate caracteristicile legate de service-ul pentru anvelope. Prețul este pe punct de lucru pe lună, iar durata implicită este de 30 de zile.",
  TyreWaste: "Deșeuri de anvelope",
  TyreWasteShort: "Deșeuri",
  TyreWidth: "Lățimea anvelopei",
  TyreWidthShort: "Lățime",
  UmPlaceholder: "PC",
  UnitOfMeasure: "Unitate de măsură",
  UnknownTranslation: "[Traducere necunoscută]",
  Unlock: "Deblocare",
  Unread: "Necitite",
  UnsupportedScreenResolution: "Rezoluția ecranului dvs. nu este acceptată",
  Update: "Actualizare",
  UpdateInProgress: "Actualizare în curs",
  UpdatePasswordText:
    "Este o idee bună să folosiți o parolă puternică pe care nu o folosiți în altă parte.",
  UpdateWorkOrder: "Actualizați comanda de lucru",
  UpdateYourPassword: "Actualizați-vă parola",
  UpdatingTyreHotel: "Actualizarea hotelului de anvelope",
  UpdatingWorkOrder: "Actualizarea comenzii de lucru",
  UploadExcel: "Încărcați Excel",
  Users: "Utilizatori",
  UsersIntroText:
    "Gestionați și urmăriți conturile de utilizator ale punctelor dvs. de lucru.",
  Value: "Valoare",
  VehicleData: "Date despre vehicul",
  VehicleType: "Tip de vehicul",
  ViewAllFilters: "Vizualizați toate filtrele",
  ViewProfile: "Vezi profilul",
  ViewWorkOrder: "Vizualizați comanda de lucru",
  ViewWorkOrderITP: "Vizualizați ITP",
  ViewWorkOrderIntro: "Explorați informațiile despre comanda de lucru.",
  VinNumber: "Serie șasiu / Număr VIN",
  VsLastMonth: "față de luna trecută",
  VsLastWeek: "față de săptămâna trecută",
  VsLastYear: "față de anul trecut",
  VsThisMonth: "față de luna aceasta",
  VsThisWeek: "față de această săptămână",
  VsThisYear: "față de anul acesta",
  VsYesterday: "față de ieri",
  Waste: "Deșeuri",
  WasteReport: "Raportul privind deșeurile",
  WeApologizeForTheInconvenience: "Ne cerem scuze pentru neplăcerile create..",
  WeeklyTotalOrders: "Total comenzi săptămânale",
  WelcomeBack: "Bine ați revenit",
  WheelType: "Tipul de roată",
  WorkerAndOperatorAccountsRestrictedAccess:
    "Toate conturile de muncitor și operator nu vor mai putea accesa conținutul până când contul de administrator nu va fi restabilit.",
  Workers: "Muncitori",
  WorkersPrint: "Muncitori",
  WorkersReport: "Raport muncitori",
  WorkOrder: "Comandă de lucru",
  WorkOrderITP: "Comandă de lucru - ITP",
  WorkOrderCreated: "Comandă de lucru creată cu succes",
  WorkOrderNotFound: "Nu am putut găsi comanda de lucru pe care o căutați.",
  WorkOrderRemoved:
    "Această comandă de lucru a fost ștearsă și nu mai este disponibilă.",
  DraftWorkOrderNotFound:
    "Nu am putut găsi schița de comandă de lucru pe care o căutați.",
  DraftWorkOrderRemoved:
    "Această schiță de lucru a fost ștearsă și nu mai este disponibilă.",
  WorkOrders: "Comenzi de lucru",
  WorkOrdersCountTotal: "Cantitatea totală a comenzilor de lucru",
  WorkOrdersIntroText: "Gestionați și urmăriți comenzile clienților dvs.",
  WorkersIntroText: "Gestionați și urmăriți angajații dvs.",
  WorkOrderType: "Tip comandă de lucru",
  WorkOrderTypePrint: "Tip comandă de lucru",
  WorkOrderUpdated: "Comanda de lucru actualizată cu succes",
  DraftUpdatedSuccessfully: "Schiță actualizată cu succes",
  WorkOrderUuidExample: "De exemplu: DEM-1234567890-BR",
  WorkOverview: "Prezentare generală a lucrărilor",
  WorkOverviewIntro: "Acestea sunt comenzile de lucru din ultima zi",
  WorkstationSettings: "Setările stației de lucru",
  Year: "Anul",
  YearlyTotalOrders: "Total comenzi pe an",
  Yes: "Da",
  YouCanBeginScanningTheCard: "Puteți începe scanarea cardului",
  YourAccountIsSuspended: "Contul dvs. este suspendat",
  YourNextBillingDateIs: "Următoarea dată de facturare este",
  SetupYourPin: "Configurați codul PIN",
  SetupPinText:
    "Creați un cod PIN din 4 cifre pentru a accelera conectarea dvs. și nu uitați să nu îl împărtășiți cu nimeni!",
  UpdateYourPin: "Actualizați-vă codul PIN",
  UpdatePinText:
    "Folosiți un cod PIN memorabil pentru a vă accelera autentificarea și nu uitați să nu îl împărtășiți cu nimeni!",
  TooShort: "Prea scurt",
  VeryWeak: "Foarte slab",
  Weak: "Slab",
  Medium: "Mediu",
  Strong: "Puternic",
  VeryStrong: "Foarte puternic",
  PasswordMustMeetFollowingRequirements:
    "Parola trebuie să îndeplinească următoarele cerințe:",
  PleaseProvideAStrongPassword: "Vă rugăm să furnizați o parolă puternică.",
  AtLeastOneLowercaseLetter: "Cel puțin o literă minusculă.",
  AtLeastOneCapitalLetter: "Cel puțin o literă majusculă.",
  AtLeastOneNumber: "Cel puțin un număr..",
  AtLeastOneSpecialCharacter: "Cel puțin un caracter special..",
  AtLeast8CharactersLong: "Cel puțin 8 caractere..",
  PasswordsMustMatch: "Parolele trebuie să se potrivească.",
  Security: "Securitate",
  SetupPin: "Configurare pin",
  UpdatePin: "Actualizare pin",
  PaymentMethodsIntroText: "Gestionați metodele de plată.",
  Working: "Activ",
  GoneHome: "Acasa",
  Lunch: "Prânz",
  Break: "Pauză",
  OnHoliday: "În vacanță",
  Away: "Plecat",
  Loading: "Încărcare",
  SelectStatus: "Selectați statusul",
  Guaranties: "Garanții",
  GuarantiesIntroText: "Gestionați și urmăriți garanțiile dumneavoastră.",
  CreatedByUser: "Creat de utilizator",
  CreatedDate: "Data creării",
  CreatedBy: "Creat de",
  Details: "Detalii",
  ChooseAClient: "Alegeți un client",
  DraftCreated: "Schiță creată cu succes",
  LiveTimesheet: "Foaie de pontaj în direct",
  LiveTimesheetIntroText:
    "Urmărire în timp real a statusurilor de lucru ale utilizatorilor dvs.",
  DayShorten: "d",
  StartDate: "Data de începere",
  EndDate: "Data de încheiere",
  CompanyInformation:
    "Companie <strong>{{name}}</strong> | C.I.F. / C.U.I <strong>{{vatNumber}}</strong> | Nr. de înregistrare <strong>{{regNumber}}</strong>",
  CompanyAddress: "Adresă <strong>{{fullAddress}}</strong>",
  CompanyContactInfo:
    " | Telefon <strong>{{phone}}</strong> | Email <strong>{{email}}</strong>",
  CompanySignature: "Semnătura companiei",
  CompanySignaturePrint: "Semnătura companiei",
  CompanyConsilierPrint: "Consilier service",
  ClientSignature: "Semnătura clientului",
  ClientSignaturePrint: "Semnătura clientului",
  SignaturePrint: "Semnătura",
  CertificateOfQualityAndGuarantee: "Certificat de calitate și garanție",
  CertificateOfQualityAndGuaranteePrint: "Certificat de calitate și garanție",
  QualityAndGuaranteeParagraphOne:
    "Unitatea service garantează lucrarea executată*) și conformitatea acesteia**), potrivit OUG140/2021 *3 luni de la dată recepției autovehiculului, dacă lucrarea nu anecesitat înlocuiri de piese sau dacă s-a executat cu piesă clientului.",
  QualityAndGuaranteeParagraphTwo:
    "** Garanția pieselor furnizate de unitate, în baza OUG140/2021 - conf. declarației producătorului/prin convenție între părți, condițional, de resp. indicațiilor deexploatare prevăzute de producătorul autovehiculului este de ___ luni.",
  QualityAndGuaranteeParagraphThree:
    "*** La cererea BENEFICIARULUI unitatea va asigura vizualizarea activităților desfășurate și înregistrări care să dovedească efectuarea inspecțiilor finale.",
  QualityAndGuaranteeParagraphFour:
    "**** Nu se acordă garanție pentru piese furnizate de client.",
  WorkOrderClientDisclaimerOne:
    "Clientul a asistat și certificat prin semnătura de mai sus strângerea roților pe autovehivul și are obligația de a verifica prezoanele după 50 de km.",
  WorkOrderCustomDisclaimerParagraphOne:
    "Nu ne asumăm răspunderea  pentru deteriorări ale anvelopei sau jantei care nu au fost observate la efectuarea schimbului (gâlme,bolfe,cipituri, uzura neuniformă,separări de material, etc)",
  WorkOrderCustomDisclaimerParagraphTwo:
    "Unitatea service garantează conform OUG140/202, garanție 10 zile pentru manoperă și pentru produsele furnizate de unitatea noastră, conform cu certificatele de garanție emise de furnizor.",
  OrderDetails: "Detalii de comandă",
  OrderDetailsPrint: "Detalii de comandă",
  CarPlate: "Plăcuța de înmatriculare a mașinii",
  CarPlatePrint: "Plăcuța de înmatriculare a mașinii",
  CarManufacturer: "Producător auto",
  CarManufacturerPrint: "Marcă auto",
  CarKm: "Mașină km",
  CarKmPrint: "Mașină km",
  RequestedServices: "Servicii solicitate",
  RequestedServicesPrint: "Servicii solicitate",
  ExecutedServices: "Servicii executate",
  ExecutedServicesPrint: "Servicii executate",
  MeasurementUnit: "UM",
  SelectAUser: "Selectați un utilizator",
  TimesheetReports: "Rapoarte de pontaj",
  TimesheetReportsIntroText: "Rapoarte de pontaj text introductiv.",
  SelectWorkOrderType: "Selectați tipul de comandă de lucru",
  DefaultWorkOrderTypeHelp:
    "Setați tipul de comandă de lucru implicit în întreaga aplicație, în formulare, căutări și filtre.",
  ModelType: "Tip de model",
  CustomerRequests: "Solicitări ale clienților",
  ProviderProposals: "Propuneri ale furnizorului",
  AddNewRequest: "Adăugați o nouă cerere",
  AddNewProposal: "Adăugați o nouă propunere",
  RequestsAndProposals: "Cereri și propuneri",
  AppointmentsTodayIntro: "Programările dumneavoastră pentru astăzi.",
  AppointmentsCalendarIntro: "Pregătiți noi programări folosind calendarul.",
  AppointmentGroup: "Grupul de programări",
  CarModel: "Model de mașină",
  CarModelMake: "Model / Marcă mașină",
  SelectOrganization: "Selectați puctul de lucru",
  SelectGroup: "Selectați grupul",
  IncludeTime: "Includeți ora",
  InvalidTime: "Oră invalidă",
  AppointmentsOverview: "Prezentare generală a programărilor",
  AppointmentsOverviewIntro: "Acestea sunt programările din ultima zi",
  AppointmentsCalendarTitle: "Calendarul programărilor",
  YouHaveNoAppointmentsForToday: "Nu aveți programări pentru astăzi..",
  DeleteAppointmentWarning:
    "Acesta va șterge <strong>permanent</strong> programarea..",
  SignIn: "Conectați-vă",
  SignInIntroText: "Vă rugăm să vă completați informațiile de mai jos",
  DontHaveAnAccount: "Nu aveți cont încă?",
  SignUp: "Înscrieți-vă",
  ForgotPassword: "Ați uitat parola",
  TermsAndConditions: "Termeni și condiții",
  PrivacyPolicy: "Politica de confidențialitate",
  LegalDocuments: "Documente juridice",
  ViewPerGroups: "Vizualizați pe grupuri",
  IncludeGroupless: "Includeți fără grupuri",
  Groupless: "Fără grup",
  SuspendedWorkOrders: "Comenzi de lucru suspendate",
  SuspendedWorkOrdersIntro:
    "Acestea sunt ordinele de lucru care așteaptă plata la finalizare",
  PinUpdatedConfirmation: "Pin actualizat cu succes!",
  PasswordChangedConfirmation: "Parola dvs. a fost actualizată cu succes!",
  GetWorkOrderErrorApi:
    "S-a produs o eroare în timpul încercării de obținere a comenzii de lucru.",
  UpdateWorkOrderErrorApi:
    "S-a produs o eroare în timpul încercării de actualizare a comenzii de lucru.",
  Intake: "Client",
  Password: "Parola",
  Name: "Nume",
  NamePrint: "Nume",
  Price: "Preț",
  PriceVAT: "Preț (cu TVA)",
  PricePrint: "Preț",
  PricePrintVAT: "Preț (cu TVA)",
  Car: "Auto",
  CarAppointment: "Mașină",
  CarPrint: "Auto",
  Duration: "Durată",
  Time: "Ora",
  Calendar: "Calendar",
  Reset: "Resetează",
  Filters: "Filtrează",
  Username: "Nume utilizator",
  Draft: "Draft",
  Organization: "Punct de lucru",
  Live: "Live",
  View: "Vezi",
  ViewMode: "Vizualizare",
  EditMode: "Editare",
  Previous: "Anterior",
  Next: "Următorul",
  Totalization: "Totalizare",
  History: "Istoric",
  Payments: "Plăți",
  Product: "Produs",
  DailyWorkOrders: "Comenzi de lucru",
  DailyWorkOrdersIntro:
    "Acestea sunt ordinele de lucru care sunt în desfășurare",
  Handheld: "Manual",
  YouHaveSwitchedAccountsSuccessfully: "Contul a fost schimbat cu succes!",
  SelectAll: "Selectați toate",
  WorkOrderPrint: "Deviz de lucru",
  CompanyReportPdf: "Raport Companie PDF",
  CompanyReportExcel: "Raport Companie Excel",
  AdvancedReportPdf: "Raport Avansat PDF",
  AdvancedReportExcel: "Raport Avansat Excel",
  DailyReportPdf: "Raport Zilnic PDF",
  DailyReportExcel: "Raport Zilnic Excel",
  MonthlyReportPdf: "Raport Lunar PDF",
  MonthlyReportExcel: "Raport Lunar Excel",
  WasteReportPdf: "Raport Deșeuri PDF",
  WasteReportExcel: "Raport Deșeuri Excel",
  WorkersReportPdf: "Raport Angajați PDF",
  WorkersReportExcel: "Raport Angajați Excel",
  WorkOrdersProductReportPdf: "Raport Produse PDF",
  WorkOrdersProductReportExcel: "Raport Produse Excel",
  GenerateWorkOrdersProductReportPdf: "Generare raport produse PDF",
  GenerateWorkOrdersProductReportExcel: "Generare raport produse Excel",
  GenerateWorkOrdersProductReportSuccess:
    "Raport produse PDF generat cu succes.",
  GenerateWorkOrdersProductReportExcelSuccess:
    "Raport produse Excel generat cu succes.",
  GenerateCompanyReportPdf: "Generare raport companie PDF.",
  GenerateCompanyReportExcel: "Generare raport companie Excel.",
  GenerateAdvancedReportPdf: "Generare raport avansat PDF.",
  GenerateAdvancedReportExcel: "Generare raport avansat Excel.",
  GenerateDailyReportPdf: "Generare raport zilnic PDF.",
  GenerateDailyReportExcel: "Generare raport zilnic Excel.",
  GenerateMonthlyReportPdf: "Generare raport lunar PDF.",
  GenerateMonthlyReportExcel: "Generare raport lunar Excel.",
  GenerateWasteReportPdf: "Generare raport deșeuri PDF.",
  GenerateWasteReportExcel: "Generare raport deșeuri Excel.",
  GenerateWorkersReportPdf: "Generare raport angajați PDF.",
  GenerateWorkersReportExcel: "Generare raport angajați Excel.",
  GenerateCompanyReportSuccess: "Raport Companie PDF generat cu succes.",
  GenerateCompanyReportExcelSuccess: "Raport Companie Excel generat cu succes.",
  GenerateAdvancedReportSuccess: "Raport Avansat PDF generat cu succes.",
  GenerateAdvancedReportExcelSuccess: "Raport Avansat Excel generat cu succes.",
  GenerateDailyReportSuccess: "Raport Zilnic PDF generat cu succes.",
  GenerateDailyReportExcelSuccess: "Raport Zilnic Excel generat cu succes.",
  GenerateMonthlyReportSuccess: "Raport Lunar PDF generat cu succes.",
  GenerateMonthlyReportExcelSuccess: "Raport Lunar Excel generat cu succes.",
  GenerateWasteReportSuccess: "Raport Deșeuri PDF generat cu succes.",
  GenerateWasteReportExcelSuccess: "Raport Deșeuri Excel generat cu succes.",
  GenerateWorkersReportSuccess: "Raport Angajați PDF generat cu succes.",
  GenerateWorkersReportExcelSuccess: "Raport Angajați Excel generat cu succes.",
  GeneratePdfReportError:
    "A apărut o eroare în timpul generării raportului PDF.",
  GenerateExcelReportError:
    "A apărut o eroare în timpul generării raportului Excel.",
  TotalWorkOrders: "Comenzi de lucru totale",
  AlreadyHaveAnAccount: "Aveți deja cont?",
  FullName: "Nume",
  ValidationCode: "Cod validare",
  EmailWithCodeSent:
    "Un email cu codul de verificare a fost trimis către tine. Te rugăm să verifici căsuța de mesaje și să lipesti codul de verificare în câmpul de cod de validare de mai sus pentru a putea continua procesul de înregistrare.",
  RetypePassword: "Reintroduceți parola",
  AddData: "Adaugă date",
  UserInfo: "Informații utilizator",
  CompanyName: "Nume companie",
  VATNumber: "Număr de TVA",
  REGNumber: "Număr de înregistrare",
  Country: "Țară",
  City: "Oraș",
  County: "Județ",
  Street: "Stradă",
  StreetNumber: "Nr. stradă",
  Website: "Website",
  SuccessfullyRegistered: "Contul tău a fost înregistrat cu succes!",
  EditAppointment: "Edit Appointment",
  TyreHotelFooterText:
    "<strong>Atenție!!!</strong> Pentru anvelopele depozitate la <strong>{{organizationName}}</strong> , răspundem <strong>12</strong> luni! După care automat intră la deșeu!",
  AppointmentDateRangeInfo: "Ore disponibile: {{start}} - {{end}}",
  Transfer: "Transferă",
  TransferWorkOrder: "Transferă ordinul de lucru.",
  TransferingWorkOrder: "Se transferă ordinul de lucru.",
  AddTyreDimensions: "Adăugați dimensiuni de anvelope",
  SelectDateTime: "Selectați data și ora",
  AddInBulk: "Adaugă mai multe",
  Add: "Adaugă",
  Services: "Servicii",
  AddProductOrServiceInBulk: "Adăugați produse / servicii",
  CustomizedWorkOrder: "Comandă de lucru personalizată",
  CustomizedWorkOrderExplanation:
    "Adăugați informații personalizate la comenzile de lucru.",
  DocumentCreator: "Document întocmit de",
  FinalReviewer: "Inspecția finală",
  WorkOrderDeadline: "Termenul de execuție",
  WorkOrderExpirationDate: "Data expirării",
  WorkOrderMetadata_createdBy: "Document întocmit de",
  WorkOrderMetadata_finalReviewer: "Inspecția finală",
  WorkOrderMetadata_deadline: "Termenul de execuție",
  WorkOrderMetadata_ownProduct: "Client al firmei",
  WorkOrderMetadata_expirationDate: "Data expirării",
  WorkOrderMetadata_smsNotification: "Notificare SMS",
  WorkOrderPdfMetadata_deadline:
    "<strong>Termenul de execuție:</strong> {{deadline}}",
  WorkOrderMetadata_clientRequested: "La cererea fermă a clientului",
  WorkOrderMetadata_withVat: "Prețul este cu TVA",
  WorkOrderMetadata_vatValue: "Valoarea TVA",
  WorkOrderMetadata_vatPercentage: "Procent TVA",
  WorkOrderMetadata_clientBringsProducts: "Clientul aduce produsele",
  TyreHotelFooterTextCustom:
    "<p>Depozitarea anvelopelor se face pe o perioadă de 6 luni, iar clientul achită la ridicarea sau înlocuirea anvelopelor suma de:</p><ul><li> 75 de lei - pentru 4 anvelope fară jante </li><li> 95 de lei - pentru 4 anvelope cu jante </li></ul>",
  DateOfTyre: "DOT",
  PrintAll: "Imprima toate",
  PrintAllTooltip: "Imprima comanda de lucru + hotel de anvelope",
  SaveAndPrint: "Salvați și imprimați",
  SMSLanguage: "Limbaj SMS",
  SelectSMSLanguage: "Selectați limba SMS",
  CalendarView: "Calendar",
  ThisWeek: "Această săptămână",
  PlateNumberSearchResults:
    "Rezultatele căutării după numărul de înmatriculare",
  PlateNumberSearchResultsText:
    "Următoarele rezultate au fost găsite pentru acest număr de înmatriculare. Vă rugăm să selectați care date doriți să le utilizați.",
  PrefillData: "Precompletează datele",
  Selected: "Selectat",
  WithRim: "Cu jantă",
  CustomerBringsProducts: "Pangus client",
  TyreCount: "Nr. anvelope",
  CustomerOrder: "Ordinea clientului",
  CustomerOrderIntroText: "Urmărește și gestionează ordinea clienților.",
  Overview: "Prezentare generală",
  CustomerOrderOverviewText: "Acestea sunt comenzile clienților pentru astăzi.",
  ViewPerGroup: "Vizualizare pe grupe",
  AddNewCustomerOrder: "Adăugați o nouă intrare",
  CarLabel: "Mașină",
  CreatedByUsername: "Creat de {{user}}",
  AccountDeletedStatus: "Contul tău este în curs de ștergere.",
  AccountDeletedInfo:
    "Odată finalizat, vei fi delogat și toate datele tale vor fi șterse.",
  SMSNotification: "Notificare SMS",
  ITPExpirationDatePrint: "Data expirării",
  WorkOrderITPPrint: "Deviz de lucru - ITP",
  SmsReminderSent: "Notificare SMS trimisă",
  ReSendSMSNotification: "Re-trimite SMS",
  Workstation: "Stație",
  RequestedByClient: "La cererea fermă a clientului",
  RequestedByClientDisclaimer:
    "Recunosc că am fost informat despre opțiunile recomandate de reparație, dar insist să procedez cu metoda de reparație solicitată.",
  TechnicalDifficultiesError:
    "Oops! Întâmpinăm niște dificultăți tehnice în acest moment. Vă rugăm să încercați mai târziu sau să contactați echipa noastră de suport pentru asistență.",
  PrintWorkOrderMetadata_createdBy: "Document întocmit de",
  PrintWorkOrderMetadata_finalReviewer: "Inspecția finală",
  PrintWorkOrderPdfMetadata_deadline:
    "<strong>Termenul de execuție:</strong> {{deadline}}",
  RequestedByClientDisclaimerPrint:
    "Recunosc că am fost informat despre opțiunile recomandate de reparație, dar insist să procedez cu metoda de reparație solicitată.",
  PlateNumberPrint: "Nr. de înmatriculare",
  YearPrint: "Anul",
  VinNumberPrint: "Serie șasiu / Număr VIN",
  ClosedAtPrint: "Închis la",
  StatusPrint: "Status",
  ClosedPrint: "Închis",
  CarModelPrint: "Model",
  DownloadPDF: "Descarcă PDF-ul",
  DownloadExcel: "Descarcă Excel-ul",
  Axis2Tyres4: "2 Axe 4 Anvelope",
  Axis2Tyres6: "2 Axe 6 Anvelope",
  Axis3Tyres10: "3 Axe 10 Anvelope",
  Axis4Tyres12: "4 Axe 12 Anvelope",
  Axis5Tyres12: "5 Axe 12 Anvelope",
  AxisAndTyres: "{{axis}} Axe {{tyres}} Anvelope",
  SignUpIntroText1:
    "Alătură-te comunității noastre și descoperă un univers de posibilități.",
  SignUpIntroText2:
    "Pentru a începe, avem nevoie de câteva informații de la tine. Te rugăm să ne furnizezi numele complet, adresa de email și numărul de telefon.",
  SignUpIntroText3:
    "După ce ai completat informațiile necesare, îți vom trimite un cod de verificare pe adresa ta de email. Pur și simplu introdu codul pentru a-ți verifica adresa de email și vei fi pregătit să explorezi toate funcțiile și beneficiile uimitoare pe care aplicația noastră web le oferă.",
  TimeSheet: "Foaie de pontaj",
  Validation: "Validare",
  Validate: "Validează",
  Finish: "Finalizează",
  AccessDenied403:
    " Acces refuzat! Din păcate, nu sunteți autorizat să efectuați această acțiune sau să accesați această resursă. Vă rugăm să luați legătura cu administratorul dumneavoastră de utilizator pentru asistență.",
  SingleTotal: "Singura",
  CommonTotal: "Comun",
  Tyre: "Anvelopă",
  StepCount: "Pasul {{step}}",
  TyreMake: "Producător anvelope",
  TyreModel: "Model anvelope",
  DiscountOnNewTyre: "Reducere la anvelope noi",
  ColorsLegend: "Legendă culori",
  WithServices: "Cu servicii",
  CloseCustomerOrder: "Închideți comanda clientului",
  Worker: "Muncitor",
  WorkOrdersExcelExport: "Exportare comenzi de lucru",
  ExportError: "A apărut o eroare în timpul exportului de date.",
  ExportOfDataInProgress: "Exportul datelor este în curs de desfășurare.",
  WorkOrdersExportSuccess: "Comenzile de lucru au fost exportate cu succes.",
  From: "De la",
  To: "Până la",
  User: "Utilizator",
  AppointmentsReportPdf: "Raport Programări PDF",
  AppointmentsReportExcel: "Raport Programări Excel",
  GenerateAppointmentsReportPdf: "Generare raport programări PDF.",
  GenerateAppointmentsReportExcel: "Generare raport programări Excel.",
  GenerateAppointmentsReportSuccess: "Raport Programări PDF generat cu succes.",
  GenerateAppointmentsReportExcelSuccess:
    "Raport Programări Excel generat cu succes.",
  SMSReport: "Raport SMS",
  SMSReportPdf: "Raport SMS PDF",
  SMSReportExcel: "Raport SMS Excel",
  GenerateSMSReportPdf: "Generare raport SMS PDF.",
  GenerateSMSReportExcel: "Generare raport SMS Excel.",
  GenerateSMSReportSuccess: "Raport SMS PDF generat cu succes.",
  GenerateSMSReportExcelSuccess: "Raport SMS Excel generat cu succes.",
  TotalSMS: "Total SMS",
  TotalAppointments: "Total programări",
  ClientBringsProducts: "Produse aduse de client",
  ProductsBroughtByClientPrint: "Produse aduse de client",

  /**
   * Account settings translations
   */
  withVat: "Cu TVA",
  vatPercentage: "Procent TVA",
  productProfitPercent: "Procent profit produs",
  smsLanguage: "Limbaj SMS",
  ownProductDiscount: "Reducere produs propriu",
  itpEnabled: "ITP activat",
  hideCreateNewTyreHotel: "Ascunde crearea unui nou hotel de anvelope",
  customLogo: "Logo personalizat",
  appointmentsStart: "Ora de începere a programărilor",
  appointmentsEnd: "Ora de încheiere a programărilor",
  appointmentsRequired: "Câmpurile obligatorii pentru programare",
  discountOnNewProduct: "Reducere pentru produsul nou",
  customized: "Personalizat",
  woRemoveDiscount: "Eliminare reducere din comanda de lucru",
  woPdfPriceLabelWithVAT: "Etichetă preț cu TVA în PDF",
  woPdfTyreTorqueNewLine: "Linie nouă pentru cuplajul la anvelope în PDF",
  thWarehouseCustom: "Depozit personalizat",
  woPdfCompanySignatureLabelRename:
    "Redenumire etichetă semnătură companie în PDF",
  woPdfClientSignatureUnderExecutedServices:
    "Semnătura clientului sub serviciile executate în PDF",
  woPdfBottomText: "Text de avertisment în subsolul PDF-ului",
  hasWoPdfConfig: "Are configurare PDF pentru comanda de lucru",
  woPdfBottomTextTitle: "Titlu text de avertisment în subsolul PDF-ului",
  woCleanRequestedServices: "Curățare servicii solicitate",
  woPdfHideProductsColumns: "Ascundere coloane produse în PDF",
  woAddProductsV2: "Adăugare produse V2",
  YesPrint: "Da",
  NoPrint: "Nu",
  AppointmentsStartTime: "Ora de începere a programărilor",
  AppointmentsEndTime: "Ora de încheiere a programărilor",
  AppointmentsStartTimeExample: "De exemplu: 08:00",
  AppointmentsEndTimeExample: "De exemplu: 18:00",
  WithGroup: "Cu grup",
  Slug: "Identificator",
  CreatedGuestAppointmentSuccess:
    "Mulțumim pentru programarea unei întâlniri cu noi! Vom lua legătura în curând pentru a confirma programarea dumneavoastră.",
  ConfirmAppointment: "Confirmă programarea",
  PublicAppointments: "Programări publice",
  Contacts: "Contacte",
  EnablePublicCalendar: "Activează calendarul public",
  Address: "Adresă",
  NoResultsFound: "Nu s-au găsit rezultate",
  Back: "Înapoi",
  LastUpdatedAt: "Ultima actualizare:",
  Service: "Serviciu",
  ServiceName: "Denumirea serviciului",
  SuspendedWorkOrdersShort: "Suspendate",
  ReportPdf: "Raport PDF",
  GenerateReportPdf: "Generare raport PDF.",
  GenerateReportSuccess: "Raport PDF generat cu succes.",
  ReportExcel: "Raport Excel",
  GenerateReportExcel: "Generare raport Excel.",
  GenerateReportExcelSuccess: "Raport Excel generat cu succes.",
  ExcludeDates: "Zile excluse din calendar-ul public",
  ExcludeSaturdays: "Excludeți sâmbetele",
  Preview: "Previzualizare",
  Close: "Închide",
  GoToWorkOrder: "Mergi la comanda de lucru",
  SMSMessages: "Mesaje SMS",
  SMSMessagesIntroText: "Personalizați-vă mesajele SMS",
  Message: "Mesaj",
  MessageType: "Tip mesaj",
  SMSMessageExplanation:
    '<p>Puteți crea propriile mesaje SMS utilizând următoarele variabile pentru a înlocui informațiile specific:</p> <ul> <li>$organizationName: Aceasta va fi înlocuită cu numele organizației.</li> <li>$from: Aceasta va fi înlocuită cu data programării.</li> <li>$address: Aceasta va fi înlocuită cu adresa organizației.</li> </ul> <p>Exemplu de mesaj: "Aveți o programare la $organizationName în data de $from, adresa: $address".</p>',
  SMSMessageExplanationWithITP:
    '<p>Puteți crea propriile mesaje SMS utilizând următoarele variabile pentru a înlocui informațiile specific:</p> <ul> <li>$organizationName: Aceasta va fi înlocuită cu numele organizației.</li> <li>$from: Aceasta va fi înlocuită cu data programării.</li> <li>$address: Aceasta va fi înlocuită cu adresa organizației.</li> <li>$plateNumber: Aceasta va fi înlocuită cu numărul de înmatriculare al mașinii. </li> </ul> <p>Exemplu de mesaj: "Aveți o programare la $organizationName în data de $from, adresa: $address".</p>',
  SMSMessageAppointmentCreated: "Programare creată",
  SMSMessageAppointmentNotification: "Notificare programare",
  SMSMessageAppointmentDeleted: "Programare anulată",
  SMSMessageITPNotification: "Notificare ITP",
  Characters: "Caractere",
  CreditsRequired: "{{credits}} Credite necesare.",
  CreditRequired: "1 Credit necesar.",
  CreditsExample: "2 Credite.",
  Credit: "Credit",
  WorkOrderProducts: "Produse comenzi de lucru",
  WorkOrderProductsReport: "Raport produse comenzi de lucru",
  TotalUM: "Total buc",
  isService: "Serviciu",
  isTyre: "Anvelope",
  PageNotFound: "Pagina nu a fost găsită",
  SuccessMessage: "Mesaj de succes",
  AppointmentsBreakInterval: "Perioada de pauză",
  AppointmentsBreakIntervalExample: "De exemplu: 13:00 - 14:00",
  Available: "Disponibil",
  Unavailable: "Indisponibil",
  Reserved: "Rezervat",
  RecalculateDiscount: "Recalculează reducerea",
  NewGuestAppointmentsDoubleClick:
    "Pentru a crea o programare, faceți dublu clic pe ora disponibilă.",
  SimpleService: "Serviciu simplu",

  /**
   * Socket event translations
   */
  WORK_ORDER_CREATED: "Comandă de lucru creată",
  WORK_ORDER_UPDATED: "Comandă de lucru actualizată",
  WORK_ORDER_DELETED: "Comandă de lucru ștearsă",
  LOYALTY_CARD_CREATED: "Card de fidelitate creat",
  LOYALTY_CARD_UPDATED: "Card de fidelitate actualizat",
  LOYALTY_CARD_DELETED: "Card de fidelitate șters",
  CLIENT_CREATED: "Client nou creat",
  CLIENT_UPDATED: "Client actualizat",
  CLIENT_DELETED: "Client șters",
  PRODUCT_CREATED: "Produs nou creat",
  PRODUCT_UPDATED: "Produs actualizat",
  PRODUCT_DELETED: "Produs șters",
  TYREHOTEL_CREATED: "A fost creat un nou hotel de anvelope",
  TYREHOTEL_UPDATED: "Hotel de anvelope actualizat",
  TYREHOTEL_DELETED: "Hotel de anvelope șters",
  APPOINTMENTS_CREATED: "A fost creată o nouă programare",
  APPOINTMENTS_UPDATED: "Programare actualizată",
  APPOINTMENTS_DELETED: "Programare ștearsă",

  /**
   * Dynamic translations
   */
  "[fieldValue] not equal with [hasToMatch]":
    "{{fieldValue}} nu este egal cu {{hasToMatch}}",
  "At least [min]": "Cel puțin {{min}} caractere..",
  "At least [minLength]": "Cel puțin {{minLength}} caractere.",
  "At most [max]": "Cel puțin {{max}} caractere.",
  "At most [maxLength]": "Cel mult {{lungime maximă}} caractere.",
  "No support for your screen size [width]x[height]":
    "În acest moment, nu oferim suport pentru dimensiunea ecranului dvs. (<strong>{{width}}</strong>x<strong>{{height}}</strong>).",
  "Please type [keyWord] to confirm":
    "Vă rugăm să tastați <strong>{{keyWord}}</strong> pentru a confirma.",
  "This work order was created [timeElapsed]":
    "Această comandă de lucru a fost creată {{timeElapsed}}.",
  "This work order was updated [timeElapsed]":
    "Această comandă de lucru a fost actualizată {{timeElapsed}}.",
  "Member since [time]": "Membru din <strong>{{time}}<strong/>.",
  "Account level: [level]": "Nivelul contului: <strong>{{level}}<strong />.",
  "Username: [username]": "Nume utilizator: <strong>{{username}}<strong />.",
  "Your next billing is due: [dueDate]":
    "Următoarea factură este scadentă în: <strong>{{dueDate}}<strong />.",
  "Hello [user], your account is currently suspended":
    "Bună ziua <strong>{{user}}</strong>, contul dvs. este în prezent suspendat.",

  /**
   * Filter fields
   */
  "filter.field_account_id": "Cont",
  "filter.field_name": "Nume",
  "filter.field_email": "Email",
  "filter.field_first_name": "Prenume",
  "filter.field_last_name": "Nume de familie",
  "filter.field_timesheet_status": "Status",
  "filter.field_total": "Total",
  "filter.field_uuid": "Comandă de lucru",
  "filter.field_discount": "Reducere",
  "filter.field_products": "Produse",
  "filter.field_quick_text": "Căutare",
  "filter.field_subtotal": "Subtotal",
  "filter.field_username": "Nume de utilizator",
  "filter.field_type": "Tipul de cont",
  "filter.field_tyre_rim": "Jantă pentru anvelope",
  "filter.field_finished": "Finalizat",
  "filter.field_is_service": "Service",
  "filter.field_car.model": "Model de mașină",
  "filter.field_company.email": "Email",
  "filter.field_tyre_name": "Numele anvelopei",
  "filter.field_car_type_id": "Tipul mașinii",
  "filter.field_fidelity": "Client fidel",
  "filter.field_vin_number": "Număr VIN",
  "filter.field_tyre_width": "Lățimea anvelopei",
  "filter.field_totalization": "Total",
  "filter.field_client_name": "Numele clientului",
  "filter.field_created_at": "Data creării",
  "filter.field_is_company": "Entitate juridică",
  "filter.field_car_number": "Numărul de înmatriculare",
  "filter.field_tyre_height": "Înălțimea pneurilor",
  "filter.field_card_number": "Numărul cardului",
  "filter.field_is_expendable": "Consumabile",
  "filter.field_plate_number": "Numărul de înmatriculare",
  "filter.field_delegate_name": "Numele delegatului",
  "filter.field_discount_type": "Tipul de reducere",
  "filter.field_organization_id": "Punct de lucru",
  "filter.field_payment_type_id": "Tipul de plată",
  "filter.field_discount_value": "Valoarea reducerii",
  "filter.field_car_plate_number": "Numărul de înmatriculare",
  "filter.field_company.vat_number": "CUI",
  "filter.field_tyreService.tyre_rim": "Janta anvelopei",
  "filter.field_is_count_price": "Preț activat",
  "filter.field_car_manufacturer": "Producător auto",
  "filter.field_car.make": "Producător auto",
  "filter.field_tyreService.tyre_width": "Lățimea anvelopei",
  "filter.field_work_order_type_id": "Tipul comenzii de lucru",
  "filter.field_tyreService.tyre_height": "Înălțimea anvelopei",
  "filter.field_company.reg_number": "J/F",
  "filter.field_created_by": "Creat de",
  "filter.field_status": "Status",
  "filter.field_user_id": "Utilizator",
  "filter.field_start_date": "Data de începere",
  "filter.field_end_date": "Data de încheiere",
  "filter.field_appointment_groups": "Grup de programări",
  "filter.field_itp": "ITP",
  "filter.field_car_make": "Producător auto",
  "filter.field_car_model": "Model de mașină",
  "filter.field_is_tyre": "Anvelopă",
  "filter.field_discount_on_new_tyre": "Reducere la anvelope noi",
  "filter.field_workers": "Muncitor",
  "filter.field_from": "Interval",
};
