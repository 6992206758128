import { useMemo } from "react";

/**
 * External imports
 */
import { toSafeInteger } from "lodash";

/**
 * Imports hooks
 */
import { useSelector } from "../../hooks";

/**
 * Utility hook for account settings
 */
export const useAccountSettings = () => {
  /**
   * Gets the account state
   */
  const { userInitialized, accountSettings, roles } = useSelector(
    (state) => state.account,
  );
  const { user } = useSelector((state) => state.auth);

  const userRole = useMemo(() => {
    if (roles.includes("admin")) return "admin";
    if (roles.includes("operator")) return "operator";
    if (user?.type === "simple_service") return "simple_service";

    return "worker";
  }, [roles, user?.type]);

  /**
   * Gets account setting by name
   */
  const getAccountSettingByName = (name: string) => {
    if (accountSettings.length > 0) {
      const setting = accountSettings.find((setting) => setting.name === name);

      return setting ? setting : undefined;
    }

    return undefined;
  };

  /**
   * Gets the account settings sms_language
   */
  const smsLanguage = useMemo(() => {
    return getAccountSettingByName("sms_language");
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Gets the account settings own_product_discount
   */
  const ownProductDiscount = useMemo(() => {
    const setting = getAccountSettingByName("own_product_discount");
    if (setting) return toSafeInteger(setting.value);

    return 0;
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Gets the account settings itp_enabled
   */
  const itpEnabled = useMemo(() => {
    const setting = getAccountSettingByName("itp_enabled");
    if (!setting) return false;

    return !!toSafeInteger(setting.value);

    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Gets the account settings custom_logo
   */
  const customLogo = useMemo(() => {
    return getAccountSettingByName("custom_logo");
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Gets the account settings appointments_start
   */
  const appointmentsStart = useMemo(() => {
    return getAccountSettingByName("appointments_start");
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Gets the account settings appointments_end
   */
  const appointmentsEnd = useMemo(() => {
    return getAccountSettingByName("appointments_end");
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Gets the account settings appointments_required
   */
  const appointmentsRequired = useMemo(() => {
    return getAccountSettingByName("appointments_required");
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Gets the account settings discount_on_new_product
   */
  const discountOnNewProduct = useMemo(() => {
    const setting = getAccountSettingByName("discount_on_new_product");
    if (!setting) return false;

    return !!toSafeInteger(setting.value);

    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Gets the account settings customized (work order)
   */
  const customized = useMemo(() => {
    const customWorkOrder = getAccountSettingByName("customized");
    return customWorkOrder ? customWorkOrder.value === "true" : false;
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Gets the account settings wo_clean_requested_services
   */
  const woCleanRequestedServices = useMemo(() => {
    const woCleanRequestedServices = getAccountSettingByName(
      "wo_clean_requested_services",
    );

    return woCleanRequestedServices
      ? woCleanRequestedServices.value === "1"
      : false;
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Checks if the account settings wo_pdf_config is enabled
   */
  const hasWoPdfConfig = useMemo(() => {
    const setting = getAccountSettingByName("wo_pdf_config");
    return setting ? setting.value === "1" : false;
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Gets the account settings th_warehouse_custom
   */
  const thWarehouseCustom = useMemo(() => {
    const setting = getAccountSettingByName("wo_pdf_config");

    if (setting && setting.options) {
      return setting.options.thWarehouseCustom as boolean;
    }
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Gets the account settings wo_pdf_bottom_text
   */
  const woPdfBottomText = useMemo(() => {
    const setting = getAccountSettingByName("wo_pdf_config");

    if (setting && setting.options) {
      return setting.options.woPdfBottomText as any as {
        value: string;
        bold?: boolean;
      }[];
    }
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Gets the account settings wo_pdf_client_signature_under_executed_services
   */
  const woPdfClientSignatureUnderExecutedServices = useMemo(() => {
    const setting = getAccountSettingByName("wo_pdf_config");

    if (setting && setting.options) {
      return setting.options
        .woPdfClientSignatureUnderExecutedServices as boolean;
    }
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Gets the account settings wo_pdf_company_signature_label_rename
   */
  const woPdfCompanySignatureLabelRename = useMemo(() => {
    const setting = getAccountSettingByName("wo_pdf_config");

    if (setting && setting.options) {
      return setting.options.woPdfCompanySignatureLabelRename as boolean;
    }
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Gets the account settings wo_pdf_price_label_with_vat
   */
  const woPdfPriceLabelWithVAT = useMemo(() => {
    const setting = getAccountSettingByName("wo_pdf_config");

    if (setting && setting.options) {
      return setting.options.woPdfPriceLabelWithVat as boolean;
    }
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Gets the account settings wo_pdf_tyre_torque_new_line
   */
  const woPdfTyreTorqueNewLine = useMemo(() => {
    const setting = getAccountSettingByName("wo_pdf_config");

    if (setting && setting.options) {
      return setting.options.woPdfTyreTorqueNewLine as boolean;
    }
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Gets the account settings wo_remove_discount
   */
  const woRemoveDiscount = useMemo(() => {
    const setting = getAccountSettingByName("wo_pdf_config");

    if (setting && setting.options) {
      return setting.options.woRemoveDiscount as boolean;
    }
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Gets the account settings wo_pdf_bottom_text_title
   */
  const woPdfBottomTextTitle = useMemo(() => {
    const setting = getAccountSettingByName("wo_pdf_config");

    if (setting && setting.options) {
      return setting.options.woPdfBottomTextTitle as string;
    }
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  /**
   * Gets the account settings wo_pdf_hide_products_columns
   */
  const woPdfHideProductsColumns = useMemo(() => {
    const setting = getAccountSettingByName("wo_pdf_config");

    if (setting && setting.options) {
      return setting.options.woPdfHideProductsColumns as boolean;
    }
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  const withVat = useMemo(() => {
    const setting = getAccountSettingByName("with_vat");

    if (setting) {
      return setting.value === "true";
    }

    return false;
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  const vatPercentage = useMemo(() => {
    const setting = getAccountSettingByName("vat_percent");

    if (setting) {
      return toSafeInteger(setting.value);
    }

    return 0;
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  const productProfitPercent = useMemo(() => {
    const setting = getAccountSettingByName("product_profit_percent");

    if (setting) {
      const value = toSafeInteger(setting.value);
      return value > 0 ? value : undefined;
    }

    return undefined;
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  const woAddProductsV2 = useMemo(() => {
    const setting = getAccountSettingByName("wo_add_products_v2");

    if (setting) {
      return setting.value === "true";
    }

    return false;
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  const hideCreateNewTyreHotel = useMemo(() => {
    const setting = getAccountSettingByName("hide_create_new_tyre_hotel");

    if (setting && setting?.value && typeof setting?.value === "string") {
      const excludedRoles = setting.value
        .split(",")
        .map((value) => value.trim());

      if (excludedRoles.includes("vulcanizator")) {
        excludedRoles.push("worker");
      }

      return excludedRoles.includes(userRole);
    }

    return false;
    // eslint-disable-next-line
  }, [userInitialized, accountSettings, userRole]);

  const smsMessageAppointmentCreated = useMemo(() => {
    const setting = getAccountSettingByName("sms_message_appointment_created");

    if (setting && setting.value) return setting;

    return undefined;
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  const smsMessageAppointmentNotification = useMemo(() => {
    const setting = getAccountSettingByName(
      "sms_message_appointment_notification",
    );

    if (setting && setting.value) return setting;

    return undefined;
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  const smsMessageAppointmentDeleted = useMemo(() => {
    const setting = getAccountSettingByName("sms_message_appointment_deleted");

    if (setting && setting.value) return setting;

    return undefined;
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  const smsMessageItpNotification = useMemo(() => {
    const setting = getAccountSettingByName("sms_message_itp_notification");

    if (setting && setting.value) return setting;

    return undefined;
    // eslint-disable-next-line
  }, [userInitialized, accountSettings]);

  return {
    withVat,
    vatPercentage,
    productProfitPercent,
    smsLanguage,
    ownProductDiscount,
    itpEnabled,
    customLogo,
    appointmentsStart,
    appointmentsEnd,
    appointmentsRequired,
    discountOnNewProduct,
    customized,
    woRemoveDiscount,
    woPdfPriceLabelWithVAT,
    woPdfTyreTorqueNewLine,
    thWarehouseCustom,
    woPdfCompanySignatureLabelRename,
    woPdfClientSignatureUnderExecutedServices,
    woPdfBottomText,
    hasWoPdfConfig,
    woPdfBottomTextTitle,
    woCleanRequestedServices,
    woPdfHideProductsColumns,
    woAddProductsV2,
    hideCreateNewTyreHotel,
    smsMessageAppointmentCreated,
    smsMessageAppointmentNotification,
    smsMessageAppointmentDeleted,
    smsMessageItpNotification,
  };
};
